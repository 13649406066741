import React from "react";

import { makeStyles, createStyles } from "@material-ui/styles";
import {
  Theme,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@material-ui/core";

import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../store";

import { actions } from '../../../services/reAuth';

import Options from './ReAuthOptions';

const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      root: {
        maxWidth: 400,
      },
      title: {
        textAlign: 'center',
      }
    }
  ),
);

const ReAuthModal: React.FC = () => {
  const classes = useStyles();

  const state = useSelector((state: RootState) => state.reAuth);
  const dispatch = useDispatch();

  const close = () => {
    dispatch(actions.close());
  }

  return (
    <Dialog
      open={state.open}
      onClose={close}
      classes={{
        paper: classes.root
      }}
    >
      <DialogTitle className={classes.title}>
        We need to verify that this is you, and not someone else is using your account.
      </DialogTitle>
      <DialogContent>
        <Options />
      </DialogContent>
    </Dialog>
  );
};

export default ReAuthModal;