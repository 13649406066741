import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { getFirebase } from '../firebase';
import { AppThunk } from '../store';
import { AuthMethod } from '../types';

import { UserData, UserRole } from 'repix-common';
import { processItem } from '../utils/firestore';

import { actions as notification } from './notifications';
import { actions as reAuthActions } from './reAuth';
import { setUerData, trackSignIn, trackSignUp } from '../utils/analytics';

export const fetchUserProfile = async (user: firebase.User): Promise<UserData | null> => {
  let userDoc;
  const { firebase } = await getFirebase();

  try {
    const userDocRef = firebase.firestore().collection('users').doc(user.uid);
    userDoc = await userDocRef.get();
  }
  catch (e) {
    console.log('error fetching profile, proceeding to create')
  }

  if (!userDoc || !userDoc.exists) {
    const userData = await createUserProfile(user);
    if (!userData) {
      // await firebase.auth().signOut();
      return null;
    }
    return userData;
  }
  else {
    const userData = processItem<UserData>(userDoc);
    return userData;
  }
};

const createUserProfile = async (user: firebase.User): Promise<UserData | null> => {
  const { firebase, FieldValue } = await getFirebase();
  const [ firstName = '', lastName = '' ] = user.displayName ? user.displayName?.split(' ') : [];
  try {
    const data: Partial<UserData> = {
      status: 'active',
      firstName,
      lastName,
      email: user.email || '',
      avatar: user.photoURL || '',
      createTime: FieldValue.serverTimestamp() as firebase.firestore.Timestamp,
      imageCount: 0,
      fontCount: 0,
      projectCount: 0,
      exportCount: 0,
      roles: [],
    }
    // console.log('userCreate', data);
    await firebase.firestore().collection('users').doc(user.uid).set(data);
    data.id = user.uid;
    data.createTime = new Date().getTime();
    data.roles = [];
    return data as UserData;
  }
  catch (e) {
    console.error(e);
    return null;
  }
}


interface UserState {
  user: UserData | null;
  signInLoading: AuthMethod | null;
  profileUpdateLoading: boolean;
  deleteAccountLoading: boolean;
  userStateLoading: boolean;
  error: string | null;
}

const initialState: UserState = {
  profileUpdateLoading: false,
  deleteAccountLoading: false,
  user: null,
  signInLoading: null,
  userStateLoading: true,
  error: null,
}

const service = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setSignInLoading(state, action: PayloadAction<AuthMethod | null>): UserState {
      return { ...state, signInLoading: action.payload, }
    },
    setUserStateLoading(state, action: PayloadAction<boolean>): UserState {
      return { ...state, userStateLoading: action.payload, }
    },
    setProfileUpdateLoading(state, action: PayloadAction<boolean>): UserState {
      return { ...state, profileUpdateLoading: action.payload, }
    },
    setDeleteAccountLoading(state, action: PayloadAction<boolean>): UserState {
      return { ...state, deleteAccountLoading: action.payload, }
    },
    setError(state, action: PayloadAction<string | null>): UserState {
      return { ...state, error: action.payload, }
    },
    setUserData(state, action: PayloadAction<UserData | null>): UserState {
      return { ...state, user: action.payload, }
    },
  }
});

const init = (): AppThunk => async (dispatch, getState) => {
  const { firebase } = await getFirebase();
  // dispatch(service.actions.setUserStateLoading(true));

  firebase.auth().onAuthStateChanged(async (fUser: any) => {
    // console.log('onAuthStateChanged', fUser);
    let user: UserData | null = null;
    if (fUser) {
      // const tokens = await firebase.auth().currentUser?.getIdTokenResult();
      // const roles = Object.keys(tokens ? tokens.claims : {}) as UserRole[];
      user = await fetchUserProfile(fUser);
      if (user) {
        setUerData(user);
      }
    }
    dispatch(service.actions.setUserData(user));
    dispatch(service.actions.setUserStateLoading(false));
  });
}

const signOut = (): AppThunk => async (dispatch, getState) => {
  const { firebase } = await getFirebase();
  await firebase.auth().signOut();
  dispatch(service.actions.setUserData(null));
}

const signIn = (method: AuthMethod): AppThunk => async (dispatch, getState) => {
  dispatch(service.actions.setSignInLoading(method));

  try {
    const { firebase, auth } = await getFirebase();
    let provider;
    if (method === 'facebook.com') {
      provider = new auth.FacebookAuthProvider();
    }
    else if (method === 'github.com') {
      provider = new auth.GithubAuthProvider();
    }
    else {
      provider = new auth.GoogleAuthProvider();
    }

    const result = await firebase.auth().signInWithPopup(provider);

    result.additionalUserInfo?.isNewUser ? trackSignUp(method) : trackSignIn(method);

    console.log(result);
  }
  catch (e) {
    if (e.code === 'auth/account-exists-with-different-credential') {
      dispatch(service.actions.setError(`Looks like you have previously signed up with ${e.email} using other auth method, please try signing in with Google.`));
    }
    else {
      dispatch(service.actions.setError(e.message));
    }
    console.error(e);
  }
  finally {
    dispatch(service.actions.setSignInLoading(null));
  }
}

interface UpdateProfileArgs {
  firstName: string;
  lastName: string;
  email: string;
}

const updateProfile = (args: UpdateProfileArgs): AppThunk => async (dispatch, getState) => {
  try {
    const { firebase, FieldValue } = await getFirebase();
    const user = getState().user.user;
    const currentUser = firebase.auth().currentUser;
    if (!user || !currentUser) {
      return;
    }

    dispatch(service.actions.setProfileUpdateLoading(true));
    if (args.email !== user.email) {
      await currentUser.updateEmail(args.email);
    }

    await firebase.firestore().collection('users').doc(user.id).update({
      updateTime: FieldValue.serverTimestamp(),
      ...args
    });

    dispatch(service.actions.setUserData({
      ...user,
      ...args,
    }))
    dispatch(notification.show({ message: 'Profile updated' }));
  }
  catch (e) {
    if (e.code === 'auth/requires-recent-login') {
      const callback = () => {
        dispatch(updateProfile(args));
        dispatch(reAuthActions.close());
      }
      dispatch(reAuthActions.open(callback));
    }
    else {
      dispatch(notification.show({ message: e.message, variant: 'error', autoHideDuration: 5000 }));
    }
    console.error(e);
  }
  finally {
    dispatch(service.actions.setProfileUpdateLoading(false));
  }
}

const deleteAccount = (): AppThunk => async (dispatch, getState) => {
  try {
    const { firebase } = await getFirebase();
    const currentUser = firebase.auth().currentUser;
    if (!currentUser) {
      return;
    }
    dispatch(service.actions.setDeleteAccountLoading(true));

    await currentUser.delete();
  }
  catch (e) {
    if (e.code === 'auth/requires-recent-login') {
      const callback = () => {
        dispatch(deleteAccount());
      }
      dispatch(reAuthActions.open(callback));
    }
    else {
      dispatch(notification.show({ message: e.message, variant: 'error', autoHideDuration: 5000 }));
    }
    console.error(e);
  }
  finally {
    dispatch(service.actions.setDeleteAccountLoading(false));
  }
}


export const actions = {
  init,
  signIn,
  signOut,
  updateProfile,
  deleteAccount,
}

export default service.reducer;