import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { getFirebase } from '../firebase';
import { AppThunk } from '../store';

import { UserFontData, FontDataLight, FontData } from 'repix-common';

import uniqueId from '../utils/uniqueId';

import { firestoreLiveQuery, firestoreQuery } from '../utils/firestore';
import keyBy from 'lodash/keyBy';
import { createCrudActions } from '../utils/redux';
import { trackEvent } from '../utils/analytics';



interface FontsState {
  listLoading: boolean;
  userFonts: UserFontData[],
  // systemFonts: { [ id: string ]: FontDataLight };
  // defaultFont: FontDataLight | null;
  uploadProgress: {
    [ id: string ]: number
  };
}

const initialState: FontsState = {
  listLoading: false,
  userFonts: [],
  uploadProgress: {},
  // systemFonts: {},
  // defaultFont: null,
}

const userFontActions = createCrudActions<UserFontData, FontsState>({
  idKey: 'id',
  itemsKey: 'userFonts',
  sort: {
    field: 'createTime',
    order: 'desc',
  }
});

const service = createSlice({
  name: 'fonts',
  initialState,
  reducers: {
    setListLoading(state, action: PayloadAction<boolean>): FontsState {
      return { ...state, listLoading: action.payload };
    },
    setList(state, action: PayloadAction<UserFontData[]>): FontsState {
      return {
        ...state,
        userFonts: action.payload,
      }
    },
    onAdd: userFontActions.onAdd,
    onUpdate: userFontActions.onUpdate,
    onDelete: userFontActions.onDelete,
    setUploadProgress(state, action: PayloadAction<{ id: string, progress: number | boolean }>): FontsState {
      const uploads = { ...state.uploadProgress };
      if (action.payload.progress === false) {
        delete uploads[ action.payload.id ]
      }
      else {
        uploads[ action.payload.id ] = action.payload.progress as number;
      }

      return {
        ...state,
        uploadProgress: uploads,
      }
    },
  }
});


const uploadFont = (file: File): AppThunk => async (dispatch, getState) => {
  const user = getState().user.user;
  const activeProject = getState().projects.activeProject;
  if (!user || !activeProject) {
    return;
  }


  try {
    trackEvent('font_upload');

    const fontId = uniqueId();
    const { db, storage, FieldValue } = await getFirebase();
    const path = `user_fonts/${user.id}/${fontId}/${file.name}`;
    const ref = storage.ref(path);

    const fontDoc: Partial<UserFontData> = {
      status: 'pending',
      user: user.id,
      project: activeProject,
      createTime: FieldValue.serverTimestamp() as firebase.firestore.Timestamp,
      path,
      fileName: file.name
    }
    await db.collection('user_fonts').doc(fontId).set(fontDoc)

    const uploadTask = ref.put(file, {
      customMetadata: {
        userId: user.id,
        projectId: activeProject,
        fontId,
      }
    });

    uploadTask.on('state_changed', (snapshot) => {
      const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      dispatch(service.actions.setUploadProgress({ id: fontId, progress }));
      if (progress === 100) {
        dispatch(service.actions.setUploadProgress({ id: fontId, progress: false }));
      }
    });

  }
  catch (e) {
    console.error(e);
  }
  finally {
  }
}

const deleteFont = (item: UserFontData): AppThunk => async (dispatch, getState) => {
  const { db, FieldValue } = await getFirebase();
  try {1
    trackEvent('font_delete');
    db.collection('user_fonts')
      .doc(item.id)
      .update({
        status: 'deleted',
        deleteTime: FieldValue.serverTimestamp() as firebase.firestore.Timestamp,
      });
  }
  catch (e) {
    console.error(e);
  }
}

let cancelLive: any;
const loadListLive = (): AppThunk => async (dispatch, getState) => {
  if (cancelLive) {
    return;
  }

  const { db } = await getFirebase();

  const user = getState().user.user;
  if (!user) {
    return;
  }

  try {
    dispatch(service.actions.setListLoading(true));
    dispatch(service.actions.setList([]));

    const query = db.collection('user_fonts')
      .where('user', '==', user.id)
      .where('status', 'in', [ 'active', 'pending', 'error' ])
      .orderBy('createTime', 'desc');

    cancelLive = firestoreLiveQuery<UserFontData>({
      query,
      onAdd: (item) => {
        dispatch(service.actions.onAdd(item));
      },
      onUpdate: (item) => {
        dispatch(service.actions.onUpdate(item));
      },
      onDelete: (item) => {
        dispatch(service.actions.onDelete(item));
      },
      skipInitialOnAdd: true,
      onInitial: (fonts) => {
        dispatch(service.actions.setList(fonts));
        dispatch(service.actions.setListLoading(false));
      }
    })
    return true;
  }
  catch (e) {
    console.error(e);
    return false;
  }
}

const reset = (): AppThunk => async (dispatch, getState) => {
  cancelLive && cancelLive();
  cancelLive = null;
}


export const loadUserFonts = async (userId: string) => {
  const { db } = await getFirebase();
  try {
    const query = db.collection('user_fonts').where('user', '==', userId);
    const fonts = await firestoreQuery<FontData>(query);
    return fonts.map(item => ({
      id: item.id,
      fontFamily: item.fontFamily,
      css: item.css,
      thumb: item.thumb.path,
      type: 'user'
    }));
  }
  catch(e) {
    console.error(e);
  }
  return [];
}







export const actions = {
  loadListLive,
  uploadFont,
  deleteFont,
  reset,
}

export default service.reducer;