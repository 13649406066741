import 'typeface-roboto';
import React from 'react';
import {DialogConsumer} from './src/components/DialogProvider';
import Notifications from "./src/components/Notifications";
import ReAuthModal from "./src/user/components/ReAuthModal/ReAuthModal";

import { trackPageView } from './src/utils/analytics';

import withState from "./withState"
export const wrapRootElement = withState


export const wrapPageElement = ({ element, props }) => {
  return (
    <React.Fragment>
      {element}
      <ReAuthModal />
      <Notifications />
      <DialogConsumer />
    </React.Fragment>
  );
};


export const onRouteUpdate = ({ location, prevLocation }) => {
  setTimeout(() => {
    // console.log('pageView', prevLocation?.pathname, location.pathname);
    trackPageView();
  }, 50);
};


// export const onClientEntry = () => {
//   if (process.env.NODE_ENV !== 'production') {
//     const whyDidYouRender = require('@welldone-software/why-did-you-render')
//     whyDidYouRender(React, {
//       // trackAllPureComponents: true,
//     })
//   }
// }