import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { getFirebase } from '../firebase';
import { AppThunk } from '../store';

import { ProjectData, CanvasData, TemplateDesign, CanvasThumb } from 'repix-common';

import keyBy from 'lodash/keyBy';

import { firestoreLiveQuery, firestoreQuery, processItem } from '../utils/firestore';
import { template } from 'lodash';
import { Portal } from '@material-ui/core';
import { SizeFilter } from '../components/Canvas';
import { loadDesigns } from './templates';
import { trackEvent } from '../utils/analytics';
import uniqueId from '../utils/uniqueId';


interface PendingUpdateData {
  action: 'add' | 'update' | 'delete',
  data: AddCanvasArgs | UpdateCanvasArgs | string
}

interface ProjectsState {
  listLoading: boolean;
  createProjectPending: boolean;
  projects: {
    [ id: string ]: ProjectData
  };
  pendingUpdates: {
    [ canvasId: string ]: PendingUpdateData
  },
  activeProject: string | null;
}

const initialState: ProjectsState = {
  listLoading: false,
  createProjectPending: false,
  projects: {},
  pendingUpdates: {},
  activeProject: null,
}

const service = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    setListLoading(state, action: PayloadAction<boolean>): ProjectsState {
      return { ...state, listLoading: action.payload, }
    },
    setCreateProjectPending(state, action: PayloadAction<boolean>): ProjectsState {
      return { ...state, createProjectPending: action.payload, }
    },
    setActiveProject(state, action: PayloadAction<string | null>): ProjectsState {
      return { ...state, activeProject: action.payload, }
    },
    setProjects(state, action: PayloadAction<{ [ id: string ]: ProjectData }>): ProjectsState {
      return {
        ...state,
        projects: {
          ...state.projects,
          ...action.payload,
        },
      }
    },
    setProject(state, action: PayloadAction<ProjectData>): ProjectsState {
      return {
        ...state,
        projects: {
          ...state.projects,
          [ action.payload.id ]: action.payload,
        },
      }
    },
    unsetProject(state, action: PayloadAction<string>): ProjectsState {
      const newList = { ...state.projects };
      delete newList[ action.payload ];

      return {
        ...state,
        projects: newList,
      }
    },
    addPendingUpdate(state, action: PayloadAction<{ [ id: string ]: PendingUpdateData }>): ProjectsState {
      return {
        ...state,
        pendingUpdates: {
          ...state.pendingUpdates,
          ...action.payload,
        }
      }
    },
    deletePendingUpdates(state, action: PayloadAction<string[]>): ProjectsState {
      const newList = { ...state.pendingUpdates };
      for (const id of action.payload) {
        delete newList[ id ];
      }
      return {
        ...state,
        pendingUpdates: newList,
      }
    }
  }
});

let cancelLiveProjects: any;

const loadProjectsLive = (): AppThunk => async (dispatch, getState) => {
  const { db } = await getFirebase();

  if (cancelLiveProjects) {
    return;
  }

  const user = getState().user.user;

  if (!user) {
    return;
  }

  try {
    dispatch(service.actions.setListLoading(true));

    const query = db.collection('projects')
      .where('user', '==', user.id)
      .where('status', '==', 'active')
      .orderBy('createTime', 'desc');

    cancelLiveProjects = firestoreLiveQuery<ProjectData>({
      query: query,
      skipInitialOnAdd: true,
      onAdd: (project) => {
        dispatch(service.actions.setProject(project));
      },
      onUpdate: (project) => {
        dispatch(service.actions.setProject(project));
      },
      onDelete: (project) => {
        dispatch(service.actions.unsetProject(project.id));
      },
      onInitial: (projects) => {
        dispatch(service.actions.setListLoading(false));
        dispatch(service.actions.setProjects(keyBy(projects, 'id')));
      }
    })
    return true;
  }
  catch (e) {
    console.error(e);
    return false;
  }
}

const updateLastOpenTime = (projectId: string): AppThunk => async (dispatch, getState) => {
  const { db, FieldValue } = await getFirebase();
  try {
    await db.collection('projects').doc(projectId).update({
      lastOpenTime: FieldValue.serverTimestamp() as firebase.firestore.Timestamp,
    })
  }
  catch (e) {
    console.error(e);
  }
}

interface CreateProjectArgs {
  name: string;
  onCreate?: (id: string) => void;
  templateId?: string;
}

const createProject = (args: CreateProjectArgs): AppThunk => async (dispatch, getState) => {
  const { db, FieldValue } = await getFirebase();
  const user = getState().user.user;

  if (!user) {
    return;
  }
  trackEvent('project_create', { fromTemplate: Boolean(args.templateId) });

  dispatch(service.actions.setCreateProjectPending(true));


  const designs = args.templateId ? await loadDesigns(args.templateId, [ 'active' ]) : null;

  const sizeFilter = designs && designs.designs.length > 0 ? {
    width: designs.designs[ 0 ].width,
    height: designs.designs[ 0 ].height,
  } : {
      width: 1242,
      height: 2688,
    }

  try {
    const project: Partial<ProjectData> = {
      status: 'active',
      user: user.id,
      createTime: FieldValue.serverTimestamp() as firebase.firestore.Timestamp,
      lastOpenTime: FieldValue.serverTimestamp() as firebase.firestore.Timestamp,
      name: args.name,
      type: 'screenshot',
      canvasCount: 0,
      thumbs: [],
      exportCount: 0,
      fontCount: 0,
      imageCount: 0,
      sizeFilter: sizeFilter,
    }
    const doc = await db.collection('projects').add(project);

    if (designs && designs.designs.length > 0) {
      for (const design of designs.designs) {
        const canvas: Partial<CanvasData> = {
          status: 'active',
          createTime: FieldValue.serverTimestamp() as firebase.firestore.Timestamp,
          project: doc.id,
          user: user.id,
          width: design.width,
          height: design.height,
          data: design.data,
          thumb: design.thumb,
          template: design.template,
          design: design.id,
        }
        await db.collection('projects').doc(doc.id).collection('project_designs').add(canvas);
      }
    }
    args.onCreate && args.onCreate(doc.id);
  }
  catch (e) {
    console.error(e);
  }
  finally {
    dispatch(service.actions.setCreateProjectPending(false));
  }
}

const renameProject = (projectId: string, name: string): AppThunk => async (dispatch, getState) => {
  const { db, FieldValue } = await getFirebase();
  try {

    await db.collection('projects').doc(projectId).update({
      name,
      updateTime: FieldValue.serverTimestamp() as firebase.firestore.Timestamp,
    })

  }
  catch (e) {
    console.error(e);
  }
}

const updateSizeFilter = (projectId: string, sizeFilter: SizeFilter): AppThunk => async (dispatch, getState) => {
  const { db, FieldValue } = await getFirebase();
  try {

    await db.collection('projects').doc(projectId).update({
      sizeFilter,
      updateTime: FieldValue.serverTimestamp() as firebase.firestore.Timestamp,
    })

  }
  catch (e) {
    console.error(e);
  }
}

const deleteProject = (projectId: string): AppThunk => async (dispatch, getState) => {
  const { db, FieldValue } = await getFirebase();
  try {

    await db.collection('projects').doc(projectId).update({
      status: 'deleted',
      deleteTime: FieldValue.serverTimestamp() as firebase.firestore.Timestamp,
    })

    trackEvent('project_delete');

  }
  catch (e) {
    console.error(e);
  }
}


export interface AddCanvasArgs {
  id: string;
  width: number;
  height: number;
  data?: string;
  thumb: CanvasThumb;
  template?: string;
  design?: string;
}

const addCanvas = (projectId: string, data: AddCanvasArgs): AppThunk => async (dispatch, getState) => {
  try {
    trackEvent('project_design_create');
    dispatch(service.actions.addPendingUpdate({
      [ data.id ]: {
        action: 'add',
        data: data,
      }
    }));
    dispatch(saveChanges(projectId));
  }
  catch (e) {
    console.error(e);
  }
  finally {
  }
}

const deleteCanvas = (projectId: string, canvasId: string): AppThunk => async (dispatch, getState) => {
  trackEvent('project_design_delete');
  dispatch(service.actions.addPendingUpdate({
    [ canvasId ]: {
      action: 'delete',
      data: canvasId,
    }
  }));
  dispatch(saveChanges(projectId));
}

export interface UpdateCanvasArgs {
  id: string;
  width: number;
  height: number;
  data: string;
  thumb: CanvasThumb;
}
const updateCanvas = (projectId: string, data: UpdateCanvasArgs): AppThunk => async (dispatch, getState) => {
  const pendingChanges = getState().projects.pendingUpdates;
  if (pendingChanges[ data.id ] && pendingChanges[ data.id ].action === 'add') {

    const existingData = { ...pendingChanges[ data.id ].data as AddCanvasArgs };
    existingData.width = data.width;
    existingData.height = data.height;
    existingData.data = data.data;

    dispatch(service.actions.addPendingUpdate({
      [ data.id ]: {
        action: 'add',
        data: existingData,
      }
    }));

  }
  else {
    dispatch(service.actions.addPendingUpdate({
      [ data.id ]: {
        action: 'update',
        data: data
      }
    }));
  }

  dispatch(saveChanges(projectId));
}

interface SaveThumbArgs {
  userId: string;
  projectId: string;
  designId: string;
  thumb: CanvasThumb;
}

const saveThumb = async ({ projectId, designId, thumb, userId }: SaveThumbArgs): Promise<CanvasThumb> => {
  const { storage } = await getFirebase();
  const path = `user_projects/${userId}/${projectId}/${designId}-${new Date().getTime().toString()}.png`;
  const ref = storage.ref(path);
  
  await ref.putString(thumb.path, 'data_url', {
    customMetadata: {
      userId,
      projectId,
      designId,
    },
    cacheControl: 'public, max-age=31536000, immutable'
  });

  return {
    ...thumb,
    path
  };
}


let timeout: any = null;
const saveChanges = (projectId: string): AppThunk => async (dispatch, getState) => {
  const { db, FieldValue } = await getFirebase();

  const user = getState().user.user;

  if (!user) {
    return;
  }

  try {

    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    }

    timeout = setTimeout(async () => {

      const pendingUpdates = getState().projects.pendingUpdates;

      const ids = Object.keys(pendingUpdates);

      if (ids.length === 0) {
        return;
      }

      console.log('saving...');
      for (const id in pendingUpdates) {
        if (pendingUpdates[ id ].action === 'add') {
          console.log('adding canvas...');
          const data = pendingUpdates[ id ].data as AddCanvasArgs;
          dispatch(service.actions.deletePendingUpdates([id]));
          const thumb = await saveThumb({ projectId: projectId, designId: id, thumb: data.thumb, userId: user.id });
          const canvas: Partial<CanvasData> = {
            status: 'active',
            createTime: FieldValue.serverTimestamp() as firebase.firestore.Timestamp,
            project: projectId,
            user: user.id,
            width: data.width,
            height: data.height,
            data: data.data || '',
            thumb,
          }
          if (data.template) {
            canvas.template = data.template
          }
          if (data.design) {
            canvas.design = data.design;
          }

          await db.collection('projects').doc(projectId).collection('project_designs').doc(id).set(canvas);
        }
        else if (pendingUpdates[ id ].action === 'update') {
          console.log('updating canvas...');
          const data = pendingUpdates[ id ].data as UpdateCanvasArgs;
          dispatch(service.actions.deletePendingUpdates([id]));
          const thumb = await saveThumb({ projectId: projectId, designId: id, thumb: data.thumb, userId: user.id });
          const update = {
            updateTime: FieldValue.serverTimestamp() as firebase.firestore.Timestamp,
            width: data.width,
            height: data.height,
            data: data.data || '',
            thumb,
          };
          await db.collection('projects').doc(projectId).collection('project_designs').doc(id).update(update);
        }
        else if (pendingUpdates[ id ].action === 'delete') {
          console.log('deleting canvas...');
          await db.collection('projects').doc(projectId).collection('project_designs').doc(id).update({
            status: 'deleted',
            deleteTime: FieldValue.serverTimestamp() as firebase.firestore.Timestamp,
          });
          dispatch(service.actions.deletePendingUpdates([id]));
        }
      }
    }, 1500);


  }
  catch (e) {
    console.error(e);
  }
  finally {
  }

}


export const loadCanvases = async (projectId: string, userId: string) => {
  const { db } = await getFirebase();

  const query = db.collection('projects')
    .doc(projectId)
    .collection('project_designs')
    .where('user', '==', userId)
    .where('status', '==', 'active')
    .orderBy('createTime', 'asc');

  const canvases = await firestoreQuery<CanvasData>(query);

  const fonts: string[] = [];


  for (const canvas of canvases) {
    if (!canvas.data) {
      continue;
    }

    const data = JSON.parse(canvas.data);
    for (const obj of data.objects) {
      if ((obj.type === 'textbox' || obj.type === 'text') && obj.fontFamily && obj.data?.fontId) {
        fonts.push(obj.data.fontId);
      }
    }
  }

  return { canvases, fonts };
}

export const actions = {
  loadProjectsLive,
  createProject,
  addCanvas,
  deleteCanvas,
  updateCanvas,
  renameProject,
  deleteProject,
  updateLastOpenTime,
  setActiveProject: service.actions.setActiveProject,
  updateSizeFilter,
}

export default service.reducer;