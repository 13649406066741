import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import orderBy from 'lodash/orderBy';

import { getFirebase } from '../firebase';
import { AppThunk } from '../store';

import { Device, StockPhoto, StockPhotoCategory } from 'repix-common';

import uniqueId from '../utils/uniqueId';

import { firestoreLiveQuery, firestoreQuery } from '../utils/firestore';
import { createCrudActions } from '../utils/redux';





interface StockPhotosState {
  listLoading: boolean;
  uploadProgress: {
    [ id: string ]: number
  };
  localCache: {
    [ id: string ]: string
  };
  list: {
    [ category: string ]: StockPhoto[]
  };
  categories: StockPhotoCategory[];
}

const imageActions = createCrudActions<StockPhoto, StockPhotosState>({
  idKey: 'id',
  itemsKey: 'photos',
  sort: {
    field: 'createTime',
    order: 'desc',
  }
});

const initialState: StockPhotosState = {
  listLoading: false,
  list: {},
  categories: [],
  uploadProgress: {},
  localCache: {},
}

const service = createSlice({
  name: 'stockPhotos',
  initialState,
  reducers: {
    setListLoading(state, action: PayloadAction<boolean>): StockPhotosState {
      if (action.payload) {
        return { ...state, listLoading: action.payload, }
      }
      else {
        return { ...state, listLoading: action.payload }
      }
    },
    setList(state, action: PayloadAction<{ [ category: string ]: StockPhoto[] }>): StockPhotosState {
      return {
        ...state,
        list: {
          ...state.list,
          ...action.payload
        },
      }
    },
    onAdd(state, action: PayloadAction<{ category: string, photo: StockPhoto }>): StockPhotosState {
      const category = action.payload.category;
      const newList = orderBy([
        ...state.list[ category ],
        action.payload.photo,
      ], 'createTime', 'desc');
      return {
        ...state,
        list: {
          ...state.list,
          [ category ]: newList
        }
      }
    },
    onUpdate(state, action: PayloadAction<{ category: string, photo: StockPhoto }>): StockPhotosState {
      const category = action.payload.category;
      const newList = state.list[ category ].map(item => item.id === action.payload.photo.id ? action.payload.photo : item);
      return {
        ...state,
        list: {
          ...state.list,
          [ category ]: orderBy(newList, 'createTime', 'desc')
        }
      }
    },
    onDelete(state, action: PayloadAction<{ category: string, photo: StockPhoto }>): StockPhotosState {
      const category = action.payload.category;
      const newList = state.list[ category ].filter(item => item.id !== action.payload.photo.id);
      return {
        ...state,
        list: {
          ...state.list,
          [ category ]: newList
        }
      }
    },
    setCategories(state, action: PayloadAction<StockPhotoCategory[]>): StockPhotosState {
      return {
        ...state,
        categories: action.payload,
      }
    },
    setLocalCache(state, action: PayloadAction<{ id: string, src: string | boolean }>): StockPhotosState {
      const localCache = { ...state.localCache };
      if (action.payload.src === false) {
        delete localCache[ action.payload.id ]
      }
      else {
        localCache[ action.payload.id ] = action.payload.src as string;
      }

      return {
        ...state,
        localCache,
      }
    },
    setUploadProgress(state, action: PayloadAction<{ id: string, progress: number | boolean }>): StockPhotosState {
      const uploads = { ...state.uploadProgress };
      if (action.payload.progress === false) {
        delete uploads[ action.payload.id ]
      }
      else {
        uploads[ action.payload.id ] = action.payload.progress as number;
      }

      return {
        ...state,
        uploadProgress: uploads,
      }
    }

  }
});


const uploadPhoto = (file: File, src: string): AppThunk => async (dispatch, getState) => {
  const user = getState().user.user;
  if (!user) {
    return;
  }

  try {
    const { db, storage, FieldValue } = await getFirebase();
    const photoId = uniqueId();
    const path = `stock_photos/${photoId}/${photoId}.png`;
    const ref = storage.ref(path);

    const photoDoc: Partial<StockPhoto> = {
      status: 'pending',
      createTime: FieldValue.serverTimestamp() as firebase.firestore.Timestamp,
      type: file.type,
      original: {
        fileSize: file.size,
        width: 0,
        height: 0,
        path,
      }
    }
    await db.collection('stock_photos').doc(photoId).set(photoDoc);

    dispatch(service.actions.setLocalCache({ id: photoId, src }));

    const uploadTask = ref.put(file, {
      customMetadata: {
        photoId,
        userId: user.id,
      }
    });

    uploadTask.on('state_changed', (snapshot) => {
      const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      console.log("progress", photoId, progress);
      dispatch(service.actions.setUploadProgress({ id: photoId, progress }));
      if (progress === 100) {
        dispatch(service.actions.setUploadProgress({ id: photoId, progress: false }));
      }
    });
  }
  catch (e) {
    console.error(e);
  }
  finally {
  }
}

const deletePhoto = (id: string): AppThunk => async (dispatch, getState) => {
  const { db, FieldValue } = await getFirebase();
  try {
    db.collection('stock_photos').doc(id).update({
      status: 'deleted',
      deleteTime: FieldValue.serverTimestamp() as firebase.firestore.Timestamp,
    });
  }
  catch (e) {
    console.error(e);
  }
}

let cancelLive: any;
const loadListLive = (category: string): AppThunk => async (dispatch, getState) => {
  if (cancelLive) {
    dispatch(reset());
  }

  const { db } = await getFirebase();

  try {
    dispatch(service.actions.setList({ [ category ]: [] }));
    dispatch(service.actions.setListLoading(true));

    const query = db.collection('stock_photos')
      .where('categories', 'array-contains', category)
      .where('status', 'in', [ 'active', 'pending' ])
      .orderBy('createTime', 'desc');

    cancelLive = firestoreLiveQuery<StockPhoto>({
      query,
      skipInitialOnAdd: true,
      onAdd: (item) => {
        dispatch(service.actions.onAdd({ photo: item, category }));
      },
      onUpdate: (item) => {
        dispatch(service.actions.onUpdate({ photo: item, category }));
      },
      onDelete: (item) => {
        dispatch(service.actions.onDelete({ photo: item, category }));
      },
      onInitial: (items) => {
        dispatch(service.actions.setList({
          [ category ]: items
        }));
        dispatch(service.actions.setListLoading(false));
      }
    })
    return true;
  }
  catch (e) {
    console.error(e);
    return false;
  }
}

const loadCategories = (): AppThunk => async (dispatch, getState) => {
  if (getState().stockPhotos.categories.length > 0) {
    return;
  }

  const { db } = await getFirebase();
  try {
    const query = db.collection('stock_photo_categories').where('status', '==', 'active').orderBy('name', 'asc');
    const categories = await firestoreQuery<StockPhotoCategory>(query);
    dispatch(service.actions.setCategories(categories));
  }
  catch (e) {
    console.error(e);
  }
}


const loadList = (category: string): AppThunk => async (dispatch, getState) => {

  if (getState().stockPhotos.list[ category ]) {
    return;
  }

  const { db } = await getFirebase();

  try {
    const query = db.collection('stock_photos')
      .where('categories', 'array-contains', category)
      .where('status', '==', 'active')
      .orderBy('createTime', 'desc');

    const photos = await firestoreQuery<StockPhoto>(query);
    dispatch(service.actions.setList({ [ category ]: photos }));
    return true;
  }
  catch (e) {
    console.error(e);
    return false;
  }
  finally {
    dispatch(service.actions.setListLoading(false));
  }
}

const reset = (): AppThunk => async (dispatch, getState) => {
  cancelLive && cancelLive();
  cancelLive = null;
}



export const actions = {
  loadList,
  loadListLive,
  uploadPhoto,
  deletePhoto,
  loadCategories,
  reset,
}

export default service.reducer;