import React from "react";

import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";


import { useDispatch, useSelector } from "react-redux";
import { actions } from '../../../services/reAuth';
import { RootState } from "../../../store";

import AuthButton from '../AuthButton';

const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      root: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      },
      spacer: {
        flex: 1,
      }
    }
  ),
);

const ReAuthOptions: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const state = useSelector((state: RootState) => state.reAuth);

  const onClick = () => {
    dispatch(actions.reAuth(state.method));
  }

  return (
    <div className={classes.root}>
      <div className={classes.spacer} />
      <AuthButton
        loading={state.loading}
        type={state.method}
        onClick={onClick}
      />
      <div className={classes.spacer} />
    </div>
  );
};

export default ReAuthOptions;