import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AppThunk } from '../store';
import { getFirebase } from '../firebase';
import { AuthMethod } from '../types';


interface ReAuthState {
  loading: boolean;
  method: AuthMethod;
  open: boolean;
}


const initialState: ReAuthState = {
  loading: false,
  method: 'google.com',
  open: false,
};


let _callback: (() => void) | null = null;
let _verificationId: string | null = null;

const service = createSlice({
  name: 'reAuth',
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>): ReAuthState {
      return { ...state, loading: action.payload, }
    },
    setOpen(state, action: PayloadAction<boolean>): ReAuthState {
      return { ...state, open: action.payload, }
    },
    setMethod(state, action: PayloadAction<AuthMethod>): ReAuthState {
      return { ...state, method: action.payload, }
    },
  }
});

const close = (): AppThunk => async (dispatch, getState) => {
  _callback = null;
  dispatch(service.actions.setOpen(false));
}

const reAuth = (method: AuthMethod): AppThunk => async (dispatch, getState) => {
  try {
    const { firebase, auth } = await getFirebase();
    dispatch(service.actions.setLoading(true));
    let provider;
    if (method === 'facebook.com') {
      provider = new auth.FacebookAuthProvider();
    }
    else if (method === 'twitter.com') {
      provider = new auth.TwitterAuthProvider();
    }
    else {
      provider = new auth.GoogleAuthProvider();
    }

    const result = await firebase.auth().signInWithPopup(provider);

    console.log(result);
  }
  catch (e) {
    console.error(e);
  }
  finally {
    _callback && _callback();
    dispatch(service.actions.setLoading(false));
  }
}

const open = (callback: () => void): AppThunk => async (dispatch, getState) => {
  const { firebase } = await getFirebase();
  try {
    const user = getState().user.user;
    const currentUser = firebase.auth().currentUser;

    if (!user || !currentUser) {
      return;
    }

    const method = currentUser.providerData[ 0 ]?.providerId;
    if (!method) {
      return;
    }

    _callback = callback;

    dispatch(service.actions.setMethod(method as AuthMethod));
    dispatch(service.actions.setOpen(true));
  }
  catch (e) {
    console.error(e);
  }
}


export const actions = {
  open,
  close,
  reAuth,
};

export default service.reducer;