import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { getFirebase } from '../firebase';
import { AppThunk } from '../store';

import { StatsData } from 'repix-common';
import { firestoreLiveDoc } from '../utils/firestore';



interface StatsState {
  loading: boolean;
  summary: StatsData | null,
}

const initialState: StatsState = {
  loading: false,
  summary: null,
}



const service = createSlice({
  name: 'exports',
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>): StatsState {
      return { ...state, loading: action.payload };
    },
    setData(state, action: PayloadAction<StatsData>): StatsState {
      return {
        ...state,
        summary: action.payload,
      }
    },
  }
});


let cancelLive: any;

const loadSummary = (): AppThunk => async (dispatch, getState) => {
  const { db } = await getFirebase();
  try {
    if (cancelLive) {
      return;
    }

    dispatch(service.actions.setLoading(true));

    firestoreLiveDoc<StatsData>({
      ref: db.collection('stats').doc('summary'),
      onGet: (data) => {
        dispatch(service.actions.setData(data));
        dispatch(service.actions.setLoading(false));
      },
      onUpdate: (data) => {
        dispatch(service.actions.setData(data));
      },
      onError: (e) => {
        console.error(e);
      }
    })
  }
  catch (e) {
    console.error(e);
  }
  finally {
  }
}

const reset = (): AppThunk => async (dispatch, getState) => {
  try {
    cancelLive && cancelLive();
    cancelLive = null;
  }
  catch(e) {
    console.error(e);
  }
  finally {
  }
}



export const actions = {
  loadSummary,
  reset
}

export default service.reducer;