import React, { useEffect } from "react";
import { useSnackbar } from 'notistack';
import { Link } from '@reach/router';
import { useSelector, useDispatch } from "react-redux";
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";

import { RootState } from "../store";
import { actions } from '../services/notifications';

let displayed: string[] = [];

const storeDisplayed = (id: string) => {
  displayed = [ ...displayed, id ];
};

const removeDisplayed = (id: string) => {
  displayed = displayed.filter(key => id !== key)
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      closeIcon: {
        color: '#ccc',
      },
      actions: {

      },

      viewButton: {
        color: '#ccc',
        marginRight: theme.spacing(1),
      }
    }
  ),
);

const Notifications: React.FC = () => {
  const snack = useSnackbar();
  const classes = useStyles();

  const notifications = useSelector((state: RootState) => state.notifications);

  const dispatch = useDispatch();

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {

    notifications.forEach(item => {

      if (item.dismissed) {
        snack.closeSnackbar(item.key);
        return;
      }

      if (displayed.includes(item.key)) {
        return;
      }

      storeDisplayed(item.key);

      snack.enqueueSnackbar(item.message, {
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        key: item.key,
        variant: item.type === 'error' ? 'error' : 'default',
        autoHideDuration: item.autoHideDuration,

        action: (key: string) => {
          return (
            <div className={classes.actions}>
              {
                item.targetUrl ? <Button className={classes.viewButton} component={Link} to={item.targetUrl}>View</Button> : null
              }
              <IconButton className={classes.closeIcon} onClick={() => dispatch(actions.dismiss(key))}><CloseIcon /></IconButton>
            </div>
          );
        },
        onExited: (event) => {
          removeDisplayed(item.key);
          dispatch(actions.remove(item.key));
        }
      });

    });
  }, [ notifications ]);

  return null;
};

export default Notifications;