import { amber as primary, pink as secondary } from "@material-ui/core/colors"
import { createMuiTheme } from "@material-ui/core/styles"

const theme = createMuiTheme({
  palette: {
    primary,
    secondary,
  },
})

export default theme
