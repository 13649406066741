import firebase from "firebase/app"

let instance: firebase.app.App;

export const getFirebase = async () => {
  if (instance) {
    return {
      firebase: instance,
      auth: firebase.auth,
      db: instance.firestore(),
      storage: instance.storage(),
      FieldValue: firebase.firestore.FieldValue,
      analytics: instance.analytics(),
    };
  }

  await Promise.all([
    import("firebase/auth"),
    import("firebase/storage"),
    import("firebase/firestore"),
    import("firebase/analytics"),
  ]);

  const firebaseConfig = {
    apiKey: process.env.FIREBASE_API_KEY,
    authDomain: process.env.FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.FIREBASE_DATABASE_URL,
    projectId: process.env.FIREBASE_PROJECT_ID,
    storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.FIREBASE_APP_ID,
    measurementId: process.env.FIREBASE_MEASUREMENT_ID,
  };

  // console.log('firebaseConfig', firebaseConfig);


  instance = firebase.initializeApp(firebaseConfig);

  return {
    firebase: instance,
    FieldValue: firebase.firestore.FieldValue,
    // EmailAuthProvider: firebase.auth.EmailAuthProvider,
    auth: firebase.auth,
    db: instance.firestore(),
    storage: instance.storage(),
    analytics: instance.analytics(),
  };
}