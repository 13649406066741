import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { getFirebase } from '../firebase';
import { AppThunk } from '../store';

import { Device, DeviceType, DevicePlatform } from 'repix-common';

import { firestoreLiveQuery, processItem } from '../utils/firestore';


interface DesignerState {
  device: Device | null;
  deviceLoading: boolean;
}

const initialState: DesignerState = {
  device: null,
  deviceLoading: false,
}

const service = createSlice({
  name: 'devices',
  initialState,
  reducers: {
    setDeviceLoading(state, action: PayloadAction<boolean>): DesignerState {
      return {
        ...state,
        deviceLoading: action.payload,
      }
    },
    setDevice(state, action: PayloadAction<Device>): DesignerState {
      return {
        ...state,
        device: action.payload,
      }
    },

  }
});

const loadDevice = (id: string): AppThunk => async (dispatch, getState) => {
  const { db } = await getFirebase();

  try {
    dispatch(service.actions.setDeviceLoading(true));

    const data = await db.collection('devices').doc(id).get();
    const device = processItem<Device>(data);
    dispatch(service.actions.setDevice(device));
    return true;

  }
  catch (e) {
    console.error(e);
    return false;
  }
  finally {
    dispatch(service.actions.setDeviceLoading(false));
  }
}




export const actions = {
  loadDevice
}

export default service.reducer;