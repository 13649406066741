import { PayloadAction } from '@reduxjs/toolkit';
import orderBy from 'lodash/orderBy';

interface CreateCrudArgs {
  idKey: string;
  itemsKey: string;
  sort?: {
    field: string;
    order: 'asc' | 'desc';
  }
}

export function createCrudActions<ItemType, StateType>({ idKey, itemsKey, sort }: CreateCrudArgs) {
  return {
    onAdd(state: StateType, action: PayloadAction<ItemType>): StateType {
      let items = [
        ...(state as any)[ itemsKey ] as ItemType[],
        action.payload,
      ]

      if (sort) {
        items = orderBy(items, sort.field, sort.order);
      }

      return {
        ...state,
        [ itemsKey ]: items
      }
    },
    onAddMany(state: StateType, action: PayloadAction<ItemType[]>): StateType {
      let items = [
        ...(state as any)[ itemsKey ] as ItemType[],
        ...action.payload,
      ]

      if (sort) {
        items = orderBy(items, sort.field, sort.order);
      }

      return {
        ...state,
        [ itemsKey ]: items
      }
    },
    onUpdate(state: StateType, action: PayloadAction<ItemType>): StateType {
      let items = (state as any)[ itemsKey ].map((item: any) =>
        item[ idKey ] === (action.payload as any)[ idKey ] ? action.payload : item
      );

      if (sort) {
        items = orderBy(items, sort.field, sort.order);
      }
      return {
        ...state,
        [ itemsKey ]: items
      }
    },
    onDelete(state: StateType, action: PayloadAction<ItemType>): StateType {
      return {
        ...state,
        [ itemsKey ]: (state as any)[ itemsKey ].filter((item: any) => item[ idKey ] !== (action.payload as any)[ idKey ])
      }
    }
  }
}