import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { getFirebase } from '../firebase';
import { AppThunk } from '../store';

import { ExportData } from 'repix-common';

import uniqueId from '../utils/uniqueId';

import { firestoreLiveQuery, firestoreQuery } from '../utils/firestore';
import { createCrudActions } from '../utils/redux';
import { trackEvent } from '../utils/analytics';



interface ExportsState {
  listLoading: boolean;
  exports: ExportData[],
}

const initialState: ExportsState = {
  listLoading: false,
  exports: [],
}

const exportListActions = createCrudActions<ExportData, ExportsState>({
  idKey: 'id',
  itemsKey: 'exports',
  sort: {
    field: 'createTime',
    order: 'desc',
  }
});

const service = createSlice({
  name: 'exports',
  initialState,
  reducers: {
    setListLoading(state, action: PayloadAction<boolean>): ExportsState {
      return { ...state, listLoading: action.payload };
    },
    setList(state, action: PayloadAction<ExportData[]>): ExportsState {
      return {
        ...state,
        exports: action.payload,
      }
    },
    onAdd: exportListActions.onAdd,
    onUpdate: exportListActions.onUpdate,
    onDelete: exportListActions.onDelete,
  }
});


const createExport = (projectId: string): AppThunk => async (dispatch, getState) => {
  const { db, FieldValue } = await getFirebase();
  try {
    const user = getState().user.user;
    if (!user) {
      return;
    }
    trackEvent('export_create');

    const exportDoc: Partial<ExportData> = {
      status: 'pending',
      user: user.id,
      project: projectId,
      createTime: FieldValue.serverTimestamp() as firebase.firestore.Timestamp,
      progress: 0,
    }
    await db.collection('exports').add(exportDoc);
  }
  catch (e) {
    console.error(e);
  }
  finally {
  }

}

const deleteExport = (item: ExportData): AppThunk => async (dispatch, getState) => {
  const { db, FieldValue } = await getFirebase();
  try {
    // db.collection('user_fonts')
    //   .doc(item.id)
    //   .update({
    //     status: 'deleted',
    //     deleteTime: FieldValue.serverTimestamp() as firebase.firestore.Timestamp,
    //   });
  }
  catch (e) {
    console.error(e);
  }
}

let cancelLive: any;
const loadListLive = (): AppThunk => async (dispatch, getState) => {
  if (cancelLive) {
    return;
  }

  const { db } = await getFirebase();

  const user = getState().user.user;
  if (!user) {
    return;
  }

  try {
    dispatch(service.actions.setListLoading(true));
    dispatch(service.actions.setList([]));

    const query = db.collection('exports')
      .where('user', '==', user.id)
      .where('status', 'in', [ 'active', 'pending', 'error' ])
      .orderBy('createTime', 'desc');

    cancelLive = firestoreLiveQuery<ExportData>({
      query,
      onAdd: (item) => {
        dispatch(service.actions.onAdd(item));
      },
      onUpdate: (item) => {
        dispatch(service.actions.onUpdate(item));
      },
      onDelete: (item) => {
        dispatch(service.actions.onDelete(item));
      },
      skipInitialOnAdd: true,
      onInitial: (fonts) => {
        dispatch(service.actions.setList(fonts));
        dispatch(service.actions.setListLoading(false));
      }
    })
    return true;
  }
  catch (e) {
    console.error(e);
    return false;
  }
}

const reset = (): AppThunk => async (dispatch, getState) => {
  cancelLive && cancelLive();
  cancelLive = null;
}







export const actions = {
  loadListLive,
  reset,
  deleteExport,
  createExport,
}

export default service.reducer;