import React from "react"
import { Provider } from "react-redux"
import store from "./src/store"
import { SnackbarProvider } from "notistack"

import { DialogProvider } from "./src/components/DialogProvider"

export default ({ element }) => {
  return (
      <Provider store={store}>
        <SnackbarProvider
          maxSnack={3}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        >
          <DialogProvider>{element}</DialogProvider>
        </SnackbarProvider>
      </Provider>
  )
}
