import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { getFirebase } from '../firebase';
import { AppThunk } from '../store';

import { DesignElement, DesignElementCategory } from 'repix-common';

import uniqueId from '../utils/uniqueId';

import { firestoreLiveQuery, firestoreQuery } from '../utils/firestore';
import orderBy from 'lodash/orderBy';

interface DesignElementsState {
  listLoading: boolean;
  categories: DesignElementCategory[];
  list: {
    [ category: string ]: DesignElement[];
  },
  uploadProgress: {
    [ id: string ]: number
  };
  localCache: {
    [ id: string ]: string
  }
}

const initialState: DesignElementsState = {
  listLoading: false,
  list: {},
  categories: [],
  uploadProgress: {},
  localCache: {},
}

const service = createSlice({
  name: 'designElements',
  initialState,
  reducers: {
    setListLoading(state, action: PayloadAction<boolean>): DesignElementsState {
      return { ...state, listLoading: action.payload };
    },
    setList(state, action: PayloadAction<{ [ category: string ]: DesignElement[] }>): DesignElementsState {
      return {
        ...state,
        list: {
          ...state.list,
          ...action.payload
        },
      }
    },
    onAdd(state, action: PayloadAction<{ category: string, item: DesignElement }>): DesignElementsState {
      const category = action.payload.category;
      const newList = orderBy([
        ...state.list[ category ],
        action.payload.item,
      ], 'createTime', 'desc');
      return {
        ...state,
        list: {
          ...state.list,
          [ category ]: newList
        }
      }
    },
    onUpdate(state, action: PayloadAction<{ category: string, item: DesignElement }>): DesignElementsState {
      const category = action.payload.category;
      const newList = state.list[ category ].map(item => item.id === action.payload.item.id ? action.payload.item : item);
      return {
        ...state,
        list: {
          ...state.list,
          [ category ]: orderBy(newList, 'createTime', 'desc')
        }
      }
    },
    onDelete(state, action: PayloadAction<{ category: string, item: DesignElement }>): DesignElementsState {
      const category = action.payload.category;
      const newList = state.list[ category ].filter(item => item.id !== action.payload.item.id);
      return {
        ...state,
        list: {
          ...state.list,
          [ category ]: newList
        }
      }
    },
    setCategories(state, action: PayloadAction<DesignElementCategory[]>): DesignElementsState {
      return {
        ...state,
        categories: action.payload,
      }
    },
    setLocalCache(state, action: PayloadAction<{ id: string, src: string | boolean }>): DesignElementsState {
      const localCache = { ...state.localCache };
      if (action.payload.src === false) {
        delete localCache[ action.payload.id ]
      }
      else {
        localCache[ action.payload.id ] = action.payload.src as string;
      }

      return {
        ...state,
        localCache,
      }
    },
    setUploadProgress(state, action: PayloadAction<{ id: string, progress: number | boolean }>): DesignElementsState {
      const uploads = { ...state.uploadProgress };
      if (action.payload.progress === false) {
        delete uploads[ action.payload.id ]
      }
      else {
        uploads[ action.payload.id ] = action.payload.progress as number;
      }

      return {
        ...state,
        uploadProgress: uploads,
      }
    }
  }
});


const uploadElement = (args: { file: File, src: string, labels: string[] }): AppThunk => async (dispatch, getState) => {
  const user = getState().user.user;
  if (!user) {
    return;
  }

  try {
    const { db, storage, FieldValue } = await getFirebase();
    const elementId = uniqueId();
    const path = `design_elements/${elementId}/${elementId}.svg`;
    const ref = storage.ref(path);

    const photoDoc: Partial<DesignElement> = {
      status: 'pending',
      createTime: FieldValue.serverTimestamp() as firebase.firestore.Timestamp,
      labels: args.labels,
      original: {
        path,
        width: 0,
        height: 0,
        fileSize: args.file.size,
      }
    }
    await db.collection('design_elements').doc(elementId).set(photoDoc);

    dispatch(service.actions.setLocalCache({ id: elementId, src: args.src }));

    const uploadTask = ref.put(args.file, {
      customMetadata: {
        elementId,
        labels: args.labels.join(','),
      }
    });

    uploadTask.on('state_changed', (snapshot) => {
      const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      dispatch(service.actions.setUploadProgress({ id: elementId, progress }));
      if (progress === 100) {
        dispatch(service.actions.setUploadProgress({ id: elementId, progress: false }));
      }
    });
  }
  catch (e) {
    console.error(e);
  }
  finally {
  }
}

const deleteElement = (item: DesignElement): AppThunk => async (dispatch, getState) => {
  const { db, FieldValue } = await getFirebase();
  try {
    db.collection('design_elements')
      .doc(item.id)
      .update({
        status: 'deleted',
        deleteTime: FieldValue.serverTimestamp() as firebase.firestore.Timestamp,
      });
  }
  catch (e) {
    console.error(e);
  }
}

let cancelLive: any;
const loadListLive = (category: string): AppThunk => async (dispatch, getState) => {
  if (cancelLive) {
    dispatch(reset());
  }

  const { db } = await getFirebase();

  try {
    dispatch(service.actions.setListLoading(true));
    dispatch(service.actions.setList({ [ category ]: [] }));

    const query = db.collection('design_elements')
      .where('categories', 'array-contains', category)
      .where('status', 'in', [ 'active', 'pending' ])
      .orderBy('createTime', 'desc');

    cancelLive = firestoreLiveQuery<DesignElement>({
      query,
      skipInitialOnAdd: true,
      onAdd: (item) => {
        dispatch(service.actions.onAdd({ category, item }));
      },
      onUpdate: (item) => {
        dispatch(service.actions.onUpdate({ category, item }));
      },
      onDelete: (item) => {
        dispatch(service.actions.onDelete({ category, item }));
      },
      onInitial: (items) => {
        dispatch(service.actions.setListLoading(false));
        dispatch(service.actions.setList({ [ category ]: items }));
      }
    })
    return true;
  }
  catch (e) {
    console.error(e);
    return false;
  }
}

const loadCategories = (): AppThunk => async (dispatch, getState) => {
  if (getState().designElements.categories.length > 0) {
    return;
  }

  const { db } = await getFirebase();
  try {
    const query = db.collection('design_element_categories').where('status', '==', 'active').orderBy('name', 'asc');
    const categories = await firestoreQuery<DesignElementCategory>(query);
    dispatch(service.actions.setCategories(categories));
  }
  catch (e) {
    console.error(e);
  }
}

const loadList = (category: string): AppThunk => async (dispatch, getState) => {

  if (Boolean(getState().designElements.list[ category ])) {
    return;
  }

  const { db } = await getFirebase();

  try {
    const query = db.collection('design_elements')
      .where('categories', 'array-contains', category)
      .where('status', '==', 'active')
      .orderBy('createTime', 'desc');

    const items = await firestoreQuery<DesignElement>(query);
    dispatch(service.actions.setList({ [ category ]: items }));
    return true;
  }
  catch (e) {
    console.error(e);
    return false;
  }
  finally {
    dispatch(service.actions.setListLoading(false));
  }
}

const reset = (): AppThunk => async (dispatch, getState) => {
  cancelLive && cancelLive();
  cancelLive = null;
}



export const actions = {
  loadList,
  loadCategories,
  loadListLive,
  uploadElement,
  deleteElement,
  reset,
}

export default service.reducer;