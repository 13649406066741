import React, { useState, useContext } from "react";

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';




const Context = React.createContext({
  type: '',
  confirm: (args: ConfirmArgs) => { },
  alert: (args: AlertArgs) => { },
  close: () => { },
  open: false,
  title: '',
  subtitle: '',
  okButton: 'Ok',
  cancelButton: 'Cancel',
  onConfirm: () => { },
  onCancel: () => { },
});


interface ConfirmArgs {
  title: string;
  subtitle?: string;
  onConfirm: () => void;
  onCancel?: () => void;
  okButton?: string;
  cancelButton?: string;
}

interface AlertArgs {
  title: string;
  subtitle?: string;
  onConfirm: () => void;
  okButton?: string;
}

export const DialogProvider: React.FC = ({ children }) => {
  const [ state, setState ] = useState({
    type: '',
    open: false,
    title: '',
    subtitle: '',
    onConfirm: () => { },
    onCancel: () => { },
    okButton: 'Ok',
    cancelButton: 'Cancel',
  })

  const confirm = (args: ConfirmArgs) => {
    setState({
      type: 'confirm',
      open: true,
      title: args.title,
      subtitle: args.subtitle || '',
      onConfirm: args.onConfirm,
      onCancel: args.onCancel || (() => { }),
      okButton: args.okButton || 'Ok',
      cancelButton: args.cancelButton || 'Cancel',
    });
  }

  const alert = (args: AlertArgs) => {
    setState({
      ...state,
      type: 'alert',
      open: true,
      title: args.title,
      subtitle: args.subtitle || '',
      onConfirm: args.onConfirm,
      okButton: args.okButton || 'Ok',
    });
  }

  const close = () => {
    setState({
      ...state,
      open: false
    });
  }


  const context = {
    confirm,
    alert,
    type: state.type,
    open: state.open,
    title: state.title,
    subtitle: state.subtitle,
    okButton: state.okButton,
    cancelButton: state.cancelButton,
    close,
    onConfirm: state.onConfirm,
    onCancel: state.onCancel,
  }

  return (
    <Context.Provider value={context}>
      {children}
    </Context.Provider>
  );
};


export const useDialog = () => {
  const context = useContext(Context);

  return {
    confirm: context.confirm,
    alert: context.alert,
  };
}


export const DialogConsumer: React.FC = () => {
  const state = useContext(Context);

  const handleClose = () => {
    state.close();
  }

  const onConfirm = () => {
    state.onConfirm();
    handleClose();
  }

  const onCancel = () => {
    state.onCancel();
    handleClose();
  }

  return (
    <Dialog
      open={state.open}
      onClose={state.type === 'confirm' ? handleClose : undefined}
      aria-labelledby={state.title}
      aria-describedby={state.subtitle}
      maxWidth="xs"
      fullWidth
    >
      <DialogTitle>{state.title}</DialogTitle>
      <DialogContent>
        {
          state.subtitle ? (
            <DialogContentText>
              {state.subtitle}
            </DialogContentText>
          ) : null
        }
      </DialogContent>
      <DialogActions>
        {
          state.type === 'confirm' ? (<Button onClick={onCancel} color="default">
            {state.cancelButton}
          </Button>) : null
        }
        <Button onClick={onConfirm} color="default">
          {state.okButton}
        </Button>
      </DialogActions>
    </Dialog>
  )
}