import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { getFirebase } from '../firebase';
import { AppThunk } from '../store';

import { DesignElement, DesignElementCategory, ProjectData, CanvasData } from 'repix-common';

import uniqueId from '../utils/uniqueId';

import { firestoreLiveQuery, firestoreQuery, firestoreLiveDoc, processItem, firestoreQueryPagination } from '../utils/firestore';

interface DesignElementsState {
  listLoading: boolean;
  listLoadingMore:boolean;
  list: ProjectData[];
  project: ProjectData | null;
  projectLoading: boolean;
}

const initialState: DesignElementsState = {
  listLoading: false,
  listLoadingMore: false,
  list: [],
  project: null,
  projectLoading: false,
}

const service = createSlice({
  name: 'adminProjects',
  initialState,
  reducers: {
    setListLoading(state, action: PayloadAction<boolean>): DesignElementsState {
      return { ...state, listLoading: action.payload };
    },
    setListLoadingMore(state, action: PayloadAction<boolean>): DesignElementsState {
      return { ...state, listLoadingMore: action.payload };
    },
    setProjectLoading(state, action: PayloadAction<boolean>): DesignElementsState {
      return { ...state, projectLoading: action.payload };
    },
    setList(state, action: PayloadAction<ProjectData[]>): DesignElementsState {
      return {
        ...state,
        list: action.payload,
      }
    },
    setData(state, action: PayloadAction<ProjectData | null>): DesignElementsState {
      return {
        ...state,
        project: action.payload,
      }
    },
  }
});



let lastProject: ProjectData;
const projectsPerPage = 50;

const loadList = (): AppThunk => async (dispatch, getState) => {
  const { db } = await getFirebase();
  try {
    dispatch(service.actions.setListLoading(true));


    let query = db.collection('projects')
      .orderBy('createTime', 'desc');

    const list = await firestoreQueryPagination<ProjectData>(query.limit(projectsPerPage));

    lastProject = list.lastItem;
    
    dispatch(service.actions.setList(list.list));

  }
  catch (e) {
    console.error(e);
  }
  finally {
    dispatch(service.actions.setListLoading(false));
  }
}


const loadMore = (): AppThunk => async (dispatch, getState) => {
  const { db } = await getFirebase();
  try {
    dispatch(service.actions.setListLoadingMore(true));


    let query = db.collection('projects')
      .orderBy('createTime', 'desc')
      .startAfter(lastProject);

    const list = await firestoreQueryPagination<ProjectData>(query.limit(projectsPerPage));

    lastProject = list.lastItem;

    const newList = [...getState().adminProjects.list, ...list.list];

    dispatch(service.actions.setList(newList));

  }
  catch (e) {
    console.error(e);
  }
  finally {
    dispatch(service.actions.setListLoadingMore(false));
  }
}


export const loadProject = async (projectId: string) => {
  const { db } = await getFirebase();
  const result = await db.collection('projects').doc(projectId).get();
  if (result.exists) {
    const project = processItem<ProjectData>(result);
    return project;
  }

  return null;
}


export const loadCanvases = async (projectId: string) => {
  const { db } = await getFirebase();

  const query = db.collection('projects')
    .doc(projectId)
    .collection('project_designs')
    .where('status', '==', 'active')
    .orderBy('createTime', 'asc');

  const canvases = await firestoreQuery<CanvasData>(query);

  const fonts: string[] = [];


  for (const canvas of canvases) {
    if (!canvas.data) {
      continue;
    }

    const data = JSON.parse(canvas.data);
    for (const obj of data.objects) {
      if ((obj.type === 'textbox' || obj.type === 'text') && obj.fontFamily && obj.data?.fontId) {
        fonts.push(obj.data.fontId);
      }
    }
  }

  return { canvases, fonts };
}




export const actions = {
  loadList,
  loadProject,
  loadMore,
}

export default service.reducer;