import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { getFirebase } from '../firebase';
import { AppThunk } from '../store';

import { TemplateData, TemplateDesign } from 'repix-common';

import uniqueId from '../utils/uniqueId';

import { firestoreLiveQuery, firestoreQuery } from '../utils/firestore';
import { createCrudActions } from '../utils/redux';
import { CanvasItem, FontLoadInfo } from '../components/Canvas';
import { stat } from 'fs';



type PendingAction = 'add' | 'update' | 'delete';
export interface PendingUpdateCanvas {
  id: string;
  width: number;
  height: number;
  data: string;
}

interface PendingUpdateData {
  action: PendingAction,
  canvas: PendingUpdateCanvas,
}

interface TemplatesState {
  listLoading: boolean;
  templates: TemplateData[],
  savePending: boolean;

  pendingUpdates: {
    [ canvasId: string ]: PendingUpdateData
  };
}
const initialState: TemplatesState = {
  listLoading: false,
  templates: [],
  savePending: false,
  pendingUpdates: {},
}

const templateListActions = createCrudActions<TemplateData, TemplatesState>({
  idKey: 'id',
  itemsKey: 'templates',
  sort: {
    field: 'createTime',
    order: 'desc',
  }
});

const service = createSlice({
  name: 'templates',
  initialState,
  reducers: {
    setListLoading(state, action: PayloadAction<boolean>): TemplatesState {
      return { ...state, listLoading: action.payload };
    },
    setSavePending(state, action: PayloadAction<boolean>): TemplatesState {
      return { ...state, savePending: action.payload };
    },
    setPendingChanges(state, action: PayloadAction<{ action: PendingAction, canvas: PendingUpdateCanvas }>): TemplatesState {
      return {
        ...state,
        pendingUpdates: {
          ...state.pendingUpdates,
          [ action.payload.canvas.id ]: {
            action: action.payload.action,
            canvas: action.payload.canvas,
          },
        }
      };
    },
    deletePendingUpdates(state, action: PayloadAction<string[]>): TemplatesState {
      const newList = { ...state.pendingUpdates };
      for (const id of action.payload) {
        delete newList[ id ];
      }
      return {
        ...state,
        pendingUpdates: newList,
      }
    },
    setList(state, action: PayloadAction<TemplateData[]>): TemplatesState {
      return {
        ...state,
        templates: action.payload,
      }
    },
    onAdd: templateListActions.onAdd,
    onUpdate: templateListActions.onUpdate,
    onDelete: templateListActions.onDelete,
  }
});

interface CreateTemplateArgs {
  name: string;
  onCreate: (id: string) => void;
}

const createTemplate = ({ name, onCreate }: CreateTemplateArgs): AppThunk => async (dispatch, getState) => {
  const { db, FieldValue } = await getFirebase();
  try {
    const user = getState().user.user;
    if (!user) {
      return;
    }
    //   id: string;
    // status: 'active' | 'pending' | 'deleted';
    // name: string;
    // createTime: firebase.firestore.Timestamp | number;
    // updateTime?: firebase.firestore.Timestamp | number;
    // deleteTime?: firebase.firestore.Timestamp | number;
    // devices: string[];
    // platforms: DevicePlatform[];
    // thumbs: TemplateDesignThumb[];
    // designCount: number;

    const templateDoc: Partial<TemplateData> = {
      status: 'pending',
      name: name,
      createTime: FieldValue.serverTimestamp() as firebase.firestore.Timestamp,
      devices: [],
      platforms: [],
      thumbs: [],
      designCount: 0,
    }
    const result = await db.collection('templates').add(templateDoc);
    onCreate(result.id);
  }
  catch (e) {
    console.error(e);
  }
  finally {
  }
}

const deleteTemplate = (id: string): AppThunk => async (dispatch, getState) => {
  const { db, FieldValue } = await getFirebase();
  try {
    await db.collection('templates').doc(id).update({
      status: 'deleted',
      deleteTime: FieldValue.serverTimestamp() as firebase.firestore.Timestamp,
    })
  }
  catch (e) {
    console.error(e);
  }
}

const setTemplateStatus = (id: string, status: string): AppThunk => async (dispatch, getState) => {
  const { db } = await getFirebase();
  try {
    await db.collection('templates').doc(id).update({
      status
    });
  }
  catch (e) {
    console.error(e);
  }
  finally {
  }

}

const renameTemplate = (templateId: string, name: string): AppThunk => async (dispatch, getState) => {
  const { db, FieldValue } = await getFirebase();
  try {

    await db.collection('templates').doc(templateId).update({
      name,
      updateTime: FieldValue.serverTimestamp() as firebase.firestore.Timestamp,
    })

  }
  catch (e) {
    console.error(e);
  }
}

let cancelLive: any;
const loadListLive = (): AppThunk => async (dispatch, getState) => {
  if (cancelLive) {
    return;
  }

  const { db } = await getFirebase();

  const user = getState().user.user;
  if (!user) {
    return;
  }

  try {
    dispatch(service.actions.setListLoading(true));
    dispatch(service.actions.setList([]));

    const query = db.collection('templates')
      .where('status', 'in', [ 'active', 'pending' ])
      .orderBy('createTime', 'desc');

    cancelLive = firestoreLiveQuery<TemplateData>({
      query,
      onAdd: (item) => {
        dispatch(service.actions.onAdd(item));
      },
      onUpdate: (item) => {
        dispatch(service.actions.onUpdate(item));
      },
      onDelete: (item) => {
        dispatch(service.actions.onDelete(item));
      },
      skipInitialOnAdd: true,
      onInitial: (fonts) => {
        dispatch(service.actions.setList(fonts));
        dispatch(service.actions.setListLoading(false));
      }
    })
    return true;
  }
  catch (e) {
    console.error(e);
    return false;
  }
}

const loadList = (): AppThunk => async (dispatch, getState) => {
  const { db } = await getFirebase();
  try {
    if (getState().templates.templates.length > 0) {
      return;
    }

    dispatch(service.actions.setListLoading(true));

    const query = db.collection('templates')
      .where('status', '==', 'active')
      .orderBy('createTime', 'desc');

    const templates = await firestoreQuery<TemplateData>(query);

    dispatch(service.actions.setList(templates));

  }
  catch (e) {
    console.error(e);
  }
  finally {
    dispatch(service.actions.setListLoading(false));
  }

}

const reset = (): AppThunk => async (dispatch, getState) => {
  cancelLive && cancelLive();
  cancelLive = null;
}

export const loadDesigns = async (templateId: string, status: string[]) => {
  const { db } = await getFirebase();

  const query = db.collection('templates')
    .doc(templateId)
    .collection('template_designs')
    .where('status', 'in', status)
    .orderBy('createTime', 'asc');

  const designs = await firestoreQuery<TemplateDesign>(query);

  const fonts: FontLoadInfo[] = [];


  for (const canvas of designs) {
    if (!canvas.data) {
      continue;
    }

    const data = JSON.parse(canvas.data);
    for (const obj of data.objects) {
      if ((obj.type === 'textbox' || obj.type === 'text') && obj.fontFamily && obj.data?.fontId) {
        const fontInfo: FontLoadInfo = {
          fontFamily: obj.fontFamily,
          css: obj.data.css,
          type: obj.data.type,
          id: obj.data.fontId,
        }
        fonts.push(fontInfo);
      }
    }
  }

  return { designs, fonts };
}


const addDesign = (canvas: CanvasItem): AppThunk => async (dispatch, getState) => {
  const { db } = await getFirebase();
  try {

    dispatch(service.actions.setPendingChanges({
      action: 'add',
      canvas: {
        id: canvas.id,
        width: canvas.width,
        height: canvas.height,
        data: canvas.data as string,
      }
    }))
  }
  catch (e) {
    console.error(e);
  }
  finally {
  }
}

const updateDesign = (canvas: CanvasItem): AppThunk => async (dispatch, getState) => {
  const { db } = await getFirebase();
  try {

    const pendingUpdates = getState().templates.pendingUpdates;
    for (const id in pendingUpdates) {
      if (id === canvas.id && pendingUpdates[ id ].action === 'add') {
        dispatch(service.actions.setPendingChanges({
          action: 'add',
          canvas: {
            id: canvas.id,
            width: canvas.width,
            height: canvas.height,
            data: canvas.data as string,
          }
        }))
        return;
      }
    }

    dispatch(service.actions.setPendingChanges({
      action: 'update',
      canvas: {
        id: canvas.id,
        width: canvas.width,
        height: canvas.height,
        data: canvas.data as string,
      }
    }))
  }
  catch (e) {
    console.error(e);
  }
  finally {
  }
}

const deleteDesign = (canvas: CanvasItem): AppThunk => async (dispatch, getState) => {
  try {

    const pendingUpdates = getState().templates.pendingUpdates;
    for (const id in pendingUpdates) {
      if (id === canvas.id && pendingUpdates[ id ].action === 'add') {
        dispatch(service.actions.deletePendingUpdates([ id ]));
        return;
      }
    }

    dispatch(service.actions.setPendingChanges({
      action: 'delete',
      canvas: {
        id: canvas.id,
        width: canvas.width,
        height: canvas.height,
        data: canvas.data as string,
      }
    }))
  }
  catch (e) {
    console.error(e);
  }
  finally {
  }
}


const saveData = (templateId: string): AppThunk => async (dispatch, getState) => {
  const { db, FieldValue } = await getFirebase();
  try {
    dispatch(service.actions.setSavePending(true));

    const promises: Promise<any>[] = [];

    const pendingUpdates = getState().templates.pendingUpdates;

    const updateIds = Object.keys(pendingUpdates);

    for (const id of updateIds) {
      const update = pendingUpdates[ id ];

      if (update.action === 'add') {
        const design: Partial<TemplateDesign> = {
          status: 'pending',
          createTime: FieldValue.serverTimestamp() as firebase.firestore.Timestamp,
          template: templateId,
          data: update.canvas.data,
          width: update.canvas.width,
          height: update.canvas.height,
        }
        const promise = db.collection('templates')
          .doc(templateId)
          .collection('template_designs')
          .doc(update.canvas.id)
          .set(design);
        promises.push(promise);
      }
      else if (update.action === 'update') {
        const design: Partial<TemplateDesign> = {
          updateTime: FieldValue.serverTimestamp() as firebase.firestore.Timestamp,
          data: update.canvas.data,
          width: update.canvas.width,
          height: update.canvas.height,
        }

        const promise = db.collection('templates')
          .doc(templateId)
          .collection('template_designs')
          .doc(update.canvas.id)
          .update(design);
        promises.push(promise);
      }
      else if (update.action === 'delete') {
        const promise = db.collection('templates')
          .doc(templateId)
          .collection('template_designs')
          .doc(update.canvas.id)
          .update({
            status: 'deleted',
            deleteTime: FieldValue.serverTimestamp() as firebase.firestore.Timestamp,
          });
        promises.push(promise);
      }
    }

    await Promise.all(promises);
    dispatch(service.actions.deletePendingUpdates(updateIds));
  }
  catch (e) {
    console.error(e);
  }
  finally {
    dispatch(service.actions.setSavePending(false));
  }

}




export const actions = {
  createTemplate,
  loadListLive,
  reset,
  loadDesigns,
  deleteTemplate,
  addDesign,
  updateDesign,
  deleteDesign,
  saveData,
  renameTemplate,
  setTemplateStatus,
  loadList,
}

export default service.reducer;