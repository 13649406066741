import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { getFirebase } from '../firebase';
import { AppThunk } from '../store';

import { Device, UserImage, UserImageType } from 'repix-common';

import uniqueId from '../utils/uniqueId';

import { firestoreLiveQuery } from '../utils/firestore';
import { createCrudActions } from '../utils/redux';
import { trackEvent } from '../utils/analytics';





interface UserImagesState {
  listLoading: boolean;
  uploadProgress: {
    [ id: string ]: number
  };
  localCache: {
    [ id: string ]: string
  }
  images: UserImage[];
}

const imageActions = createCrudActions<UserImage, UserImagesState>({
  idKey: 'id',
  itemsKey: 'images',
  sort: {
    field: 'createTime',
    order: 'desc',
  }
});

const initialState: UserImagesState = {
  listLoading: false,
  images: [],
  uploadProgress: {},
  localCache: {},
}

const service = createSlice({
  name: 'images',
  initialState,
  reducers: {
    setListLoading(state, action: PayloadAction<boolean>): UserImagesState {
      if (action.payload) {
        return { ...state, listLoading: action.payload, images: [], }
      }
      else {
        return { ...state, listLoading: action.payload }
      }
    },
    onImageAdd: imageActions.onAdd,
    onImageUpdate: imageActions.onUpdate,
    onImageDelete: imageActions.onDelete,
    setLocalCache(state, action: PayloadAction<{ id: string, src: string | boolean }>): UserImagesState {
      const localCache = { ...state.localCache };
      if (action.payload.src === false) {
        delete localCache[ action.payload.id ]
      }
      else {
        localCache[ action.payload.id ] = action.payload.src as string;
      }

      return {
        ...state,
        localCache,
      }
    },
    setUploadProgress(state, action: PayloadAction<{ id: string, progress: number | boolean }>): UserImagesState {
      const uploads = { ...state.uploadProgress };
      if (action.payload.progress === false) {
        delete uploads[ action.payload.id ]
      }
      else {
        uploads[ action.payload.id ] = action.payload.progress as number;
      }

      return {
        ...state,
        uploadProgress: uploads,
      }
    }

  }
});


const uploadImage = (args: { file: File, src: string, type: UserImageType }): AppThunk => async (dispatch, getState) => {
  const user = getState().user.user;
  const activeProject = getState().projects.activeProject;

  if (!user || !activeProject) {
    return;
  }

  try {
    trackEvent('image_upload');
    const { db, storage, FieldValue } = await getFirebase();
    const imageId = uniqueId();
    const ext = args.file.name.split('.');
    const path = `user_images/${user.id}/${imageId}.${ext[ ext.length - 1 ]}`;
    const ref = storage.ref(path);

    const imageDoc: Partial<UserImage> = {
      status: 'pending',
      user: user.id,
      project: activeProject,
      type: args.type,
      createTime: FieldValue.serverTimestamp() as firebase.firestore.Timestamp,
      fileName: args.file.name,
      fileSize: args.file.size,
      fileType: args.file.type,
      path,
    }
    dispatch(service.actions.setLocalCache({ id: imageId, src: args.src }));
    
    await db.collection('user_images').doc(imageId).set(imageDoc);


    const uploadTask = ref.put(args.file, {
      customMetadata: {
        imageId,
        userId: user.id,
        projectId: activeProject,
        imageType: args.type,
      }
    });

    uploadTask.on('state_changed', (snapshot) => {
      const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      console.log("progress", imageId, progress);
      dispatch(service.actions.setUploadProgress({ id: imageId, progress }));
      if (progress === 100) {
        // dispatch(service.actions.setLocalCache({ id: imageId, src: false }));
        dispatch(service.actions.setUploadProgress({ id: imageId, progress: false }));
      }
    });
  }
  catch (e) {
    console.error(e);
  }
  finally {
  }
}

const deleteImage = (id: string): AppThunk => async (dispatch, getState) => {
  const { db, FieldValue } = await getFirebase();
  try {
    trackEvent('image_delete');
    db.collection('user_images').doc(id).update({
      status: 'deleted',
      deleteTime: FieldValue.serverTimestamp() as firebase.firestore.Timestamp,
    });
  }
  catch (e) {
    console.error(e);
  }
}

let cancelLive: any;
const loadList = (): AppThunk => async (dispatch, getState) => {
  if (cancelLive) {
    return;
  }

  const { db } = await getFirebase();

  const user = getState().user.user;
  if (!user) {
    return;
  }


  try {
    dispatch(service.actions.setListLoading(true));

    const query = db.collection('user_images')
      .where('user', '==', user.id)
      .where('status', 'in', [ 'active', 'pending' ])
      .orderBy('createTime', 'desc');

    cancelLive = firestoreLiveQuery<UserImage>({
      query,
      onAdd: (item) => {
        dispatch(service.actions.onImageAdd(item));
      },
      onUpdate: (item) => {
        dispatch(service.actions.onImageUpdate(item));
      },
      onDelete: (item) => {
        dispatch(service.actions.onImageDelete(item));
      },
      onInitial: () => {
        dispatch(service.actions.setListLoading(false));
      }
    })
    return true;
  }
  catch (e) {
    console.error(e);
    return false;
  }
}

const reset = (): AppThunk => async (dispatch, getState) => {
  cancelLive && cancelLive();
  cancelLive = null;
}



export const actions = {
  loadList,
  uploadImage,
  deleteImage,
  reset,
}

export default service.reducer;