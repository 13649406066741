import * as firebase from 'firebase/app';



export function processItem<T>(doc: firebase.firestore.DocumentSnapshot) {
  const item: any = doc.data();
  item.id = doc.id

  for (const key in item) {
    if (item[ key ] instanceof firebase.firestore.Timestamp) {
      item[ key ] = (item[ key ] as firebase.firestore.Timestamp).seconds * 1000;
    }
  }

  return item as T;
}


export interface LiveQueryArgs<T> {
  query: firebase.firestore.Query;
  onAdd: (item: T) => void;
  onUpdate: (item: T) => void;
  onDelete: (item: T) => void;
  onInitial?: (items: T[]) => void;
  onError?: (err: Error) => void;
  skipInitialOnAdd?: boolean;
}

export function firestoreLiveQuery<ItemType>(args: LiveQueryArgs<ItemType>) {
  let initial = true;
  try {
    return args.query.onSnapshot({
      next: (snapshot) => {
        const initialList: ItemType[] = [];

        snapshot.docChanges().forEach(doc => {
          const item = processItem<ItemType>(doc.doc);
          if (doc.type === 'added') {
            initialList.push(item);
            if (!initial || !args.skipInitialOnAdd) {
              args.onAdd(item);
            }
          }
          else if (doc.type === 'modified') {
            args.onUpdate(item);
          }
          else if (doc.type === 'removed') {
            args.onDelete(item);
          }
        })
        if (initial) {
          args.onInitial && args.onInitial(initialList);
          initial = false;
        }
      },
      error: (err) => {
        console.error(err);
        args.onError && args.onError(err);
      }
    })
  }
  catch (e) {
    console.error(e);
    return false;
  }
}

export async function firestoreQuery<ItemType>(query: firebase.firestore.Query) {
  try {
    const result = await query.get();
    return result.docs.map(doc => processItem<ItemType>(doc));
  }
  catch (e) {
    console.error(e);
    return [];
  }
}

export async function firestoreQueryPagination<ItemType>(query: firebase.firestore.Query) {
  try {
    const result = await query.get();
    return {
      list: result.docs.map(doc => processItem<ItemType>(doc)),
      lastItem: result.docs[result.docs.length - 1],
    };
  }
  catch (e) {
    console.error(e);
    return {
      list: [],
      lastItem: null as any,
    };
  }
}


export interface LiveDocArgs<T> {
  ref: firebase.firestore.DocumentReference,
  onUpdate: (item: T) => void;
  onGet: (item: T) => void;
  onError?: (e: Error) => void;
}

export function firestoreLiveDoc<ItemType>(args: LiveDocArgs<ItemType>) {
  let initial = true;
  try {
    return args.ref.onSnapshot({
      next: (snapshot) => {
        const doc = processItem<ItemType>(snapshot);
        if (initial) {
          args.onGet(doc);
          initial = true;
        }
        else {
          args.onUpdate(doc);
        }
      },
      error: (err) => {
        args.onError && args.onError(err);
      }
    })
  }
  catch (e) {
    console.error(e);
    return false;
  }
}