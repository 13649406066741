import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { getFirebase } from '../firebase';
import { AppThunk } from '../store';
import slugify from 'slugify';

import {
  Device,
  DeviceType,
  DevicePlatform,
  DeviceImage,
  BackgroundDataRect,
  BackgroundDataCircle
} from 'repix-common';
import uniqueId from '../utils/uniqueId';

import { firestoreLiveQuery } from '../utils/firestore';
import { createCrudActions } from '../utils/redux';

let cancelLiveImages: any;

interface EditDeviceState {
  loading: boolean;
  data: Device | null;
  imageUpdateInProgress: boolean;
  images: DeviceImage[];
  localCache: {
    [ id: string ]: string
  }
}

const initialState: EditDeviceState = {
  loading: false,
  data: null,
  images: [],
  imageUpdateInProgress: false,
  localCache: {},
}

const imageActions = createCrudActions<DeviceImage, EditDeviceState>({
  idKey: 'id',
  itemsKey: 'images',
  sort: {
    field: 'colorName',
    order: 'asc',
  }
});

const service = createSlice({
  name: 'edit_device',
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>): EditDeviceState {
      return { ...state, loading: action.payload, }
    },
    setImageUpdateInProgress(state, action: PayloadAction<boolean>): EditDeviceState {
      return { ...state, imageUpdateInProgress: action.payload, }
    },
    setData(state, action: PayloadAction<Device | null>): EditDeviceState {
      return { ...state, data: action.payload, }
    },
    setImages(state, action: PayloadAction<DeviceImage[]>): EditDeviceState {
      return { ...state, images: [ ...action.payload ], }
    },
    onImageAdd: imageActions.onAdd,
    onImageUpdate: imageActions.onUpdate,
    onImageDelete: imageActions.onDelete,
    setLocalCache(state, action: PayloadAction<{ id: string, src: string | boolean }>): EditDeviceState {
      const localCache = { ...state.localCache };
      if (action.payload.src === false) {
        delete localCache[ action.payload.id ]
      }
      else {
        localCache[ action.payload.id ] = action.payload.src as string;
      }

      return {
        ...state,
        localCache,
      }
    },
  },
});

const reset = (): AppThunk => async (dispatch, getState) => {
  cancelLiveImages && cancelLiveImages();
  dispatch(service.actions.setData(null));
  dispatch(service.actions.setImages([]));
}

interface CreateDeviceArgs {
  name: string;
  maker: string;
  type: DeviceType;
  platform: DevicePlatform;
}

const createDevice = ({ name, type, platform, maker }: CreateDeviceArgs): AppThunk<Promise<string | null>> => async (dispatch, getState) => {
  const { db } = await getFirebase();
  try {
    dispatch(service.actions.setLoading(true));

    const order = Object.keys(getState().devices.devices).length;


    const data: Partial<Device> = {
      status: 'pending',
      maker,
      name,
      sort: order,
      type,
      platform,
    };

    const doc = await db.collection('devices').add(data);
    return doc.id;
  }
  catch (e) {
    console.error(e);
    return null;
  }
  finally {
    dispatch(service.actions.setLoading(false));
  }
}


const loadDeviceData = (id: string): AppThunk => async (dispatch, getState) => {
  const { db } = await getFirebase();
  try {
    dispatch(service.actions.setData(null));
    dispatch(service.actions.setImages([]));
    dispatch(service.actions.setLoading(true));

    const doc = await db.collection('devices').doc(id).get();
    if (!doc.exists) {
      return;
    }

    const data = doc.data() as Device;
    data.id = doc.id;
    dispatch(service.actions.setData(data));

    cancelLiveImages = firestoreLiveQuery<DeviceImage>({
      query: db.collection('devices').doc(data.id).collection('device_images'),
      onAdd: (item) => {
        dispatch(service.actions.onImageAdd(item));
      },
      onUpdate: (item) => {
        dispatch(service.actions.onImageUpdate(item));
      },
      onDelete: (item) => {
        dispatch(service.actions.onImageDelete(item));
      },
      onInitial: () => {
        dispatch(service.actions.setLoading(false));
      }
    })
  }
  catch (e) {
    console.error(e);
    return null;
  }
}

interface AddImageArgs {
  file: File,
  src?: string;
  name: string;
  color: string;
}

const addImage = (args: AddImageArgs): AppThunk<Promise<boolean>> => async (dispatch, getState) => {
  try {
    const device = getState().editDevice.data;
    if (!device) {
      return false;
    }

    const { db, storage } = await getFirebase();
    dispatch(service.actions.setImageUpdateInProgress(true));

    const imageId = uniqueId();

    if (args.src) {
      dispatch(service.actions.setLocalCache({ id: imageId, src: args.src }));
    }


    const path = `devices/${device.id}/${imageId}.png`;

    const ref = storage.ref(path);

    await ref.put(args.file, {
      customMetadata: {
        imageId,
        deviceId: device.id,
        deviceType: device.type,
        devicePlatform: device.platform,
        colorName: args.name,
        colorHex: args.color,
      }
    });

    const imageDoc: Partial<DeviceImage> = {
      status: 'pending',
      device: device.id,
      path,
      name: args.name,
      color: args.color
    }

    await db.collection('devices').doc(device.id).collection('device_images').doc(imageId).set(imageDoc);
    return true;
  }
  catch (e) {
    console.error(e);
    return false;
  }
  finally {
    dispatch(service.actions.setImageUpdateInProgress(false));
  }
}

const updateDevice = (): AppThunk => async (dispatch, getState) => {
  const { db } = await getFirebase();
  try {
    dispatch(service.actions.setLoading(true));
    const device = getState().editDevice.data;

    if (!device) {
      return;
    }
    const { id, images, ...data } = device;
    console.log('data', data);
    await db.collection('devices').doc(id).update(data);
  }
  catch (e) {
    console.error(e);
    return null;
  }
  finally {
    dispatch(service.actions.setLoading(false));
  }
}

const setMainImage = (imgId: string): AppThunk => async (dispatch, getState) => {
  const { db } = await getFirebase();
  try {
    const device = getState().editDevice.data;
    if (!device) {
      return;
    }

    dispatch(service.actions.setLoading(true));
    await db.collection('devices').doc(device.id).update({
      mainImage: imgId
    })
    dispatch(service.actions.setData({
      ...device,
      mainImage: imgId,
    }));
  }
  catch (e) {
    console.error(e);
  }
  finally {
    dispatch(service.actions.setLoading(false));
  }
}

interface UpdateImageArgs {
  imgId: string;
  name?: string;
  color?: string;
  status?: 'deleted' | 'active';

}

const updateImage = (args: UpdateImageArgs): AppThunk => async (dispatch, getState) => {
  const { db } = await getFirebase();
  const device = getState().editDevice.data;
  if (!device) {
    return false;
  }

  try {
    dispatch(service.actions.setImageUpdateInProgress(true));
    await db.collection('devices').doc(device.id).collection('device_images').doc(args.imgId).update({
      name: args.name,
      color: args.color
    });
  }
  catch (e) {
    console.error(e);
  }
  finally {
    dispatch(service.actions.setImageUpdateInProgress(false));
  }
}

const deleteImage = (deviceId: string, imgId: string): AppThunk => async (dispatch, getState) => {
  const { db } = await getFirebase();
  try {
    await db.collection('devices').doc(deviceId).collection('device_images').doc(imgId).update({
      status: 'deleted'
    })
  }
  catch (e) {
    console.error(e);
  }
}

interface SetBackgroundArgs {
  background: BackgroundDataRect | BackgroundDataCircle,
  screenSize: {
    width: number;
    height: number;
  }
}

const setBackground = ({ background, screenSize }: SetBackgroundArgs): AppThunk<Promise<boolean>> => async (dispatch, getState) => {
  const { db } = await getFirebase();
  try {
    const device = getState().editDevice.data;
    if (!device) {
      return false;
    }

    dispatch(service.actions.setLoading(true));

    dispatch(service.actions.setData({
      ...device,
      background,
      screenSize,
    }));

    const { id, ...data } = device;
    await db.collection('devices').doc(id).update({
      background,
      screenSize,
    });
    return true;
  }
  catch (e) {
    console.error(e);
    return false;
  }
  finally {
    dispatch(service.actions.setLoading(false));
  }

}

export const actions = {
  createDevice,
  loadDeviceData,
  updateDevice,
  addImage,
  updateImage,
  deleteImage,
  setMainImage,
  reset,
  setBackground,
  setData: service.actions.setData,
}

export default service.reducer;