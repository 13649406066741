import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { getFirebase } from '../firebase';
import { AppThunk } from '../store';

import { Device, DeviceType, DevicePlatform } from 'repix-common';

import keyBy from 'lodash/keyBy';

import { firestoreLiveQuery, firestoreQuery, processItem } from '../utils/firestore';
import uniqBy from 'lodash/uniqBy';


interface ColorInfo {
  name: string;
  color: string;
}

interface DevicesState {
  listLoading: boolean;
  devices: {
    [ id: string ]: Device
  };
  availableColors: ColorInfo[];
}

const initialState: DevicesState = {
  listLoading: false,
  devices: {},
  availableColors: [],
}

const service = createSlice({
  name: 'devices',
  initialState,
  reducers: {
    setListLoading(state, action: PayloadAction<boolean>): DevicesState {
      return { ...state, listLoading: action.payload, }
    },
    setDevices(state, action: PayloadAction<{ [ id: string ]: Device }>): DevicesState {
      return {
        ...state,
        availableColors: getColorList(Object.values(action.payload)),
        devices: {
          ...state.devices,
          ...action.payload,
        },
      }
    },
    setDevice(state, action: PayloadAction<Device>): DevicesState {
      const newList = {
        ...state.devices,
        [ action.payload.id ]: action.payload,
      };
      return {
        ...state,
        availableColors: getColorList(Object.values(newList)),
        devices: newList,
      }
    },
    unsetDevice(state, action: PayloadAction<string>): DevicesState {
      const devices = { ...state.devices };
      delete devices[ action.payload ];
      return {
        ...state,
        devices
      }
    },
  }
});

let cancelLive: any;

const getColorList = (devices: Device[]) => {
  // const colors: { name: string, color: string }[] = [ { "name": "Pink", "color": "#fad3da" }, { "name": "Black", "color": "#021f36" }, { "name": "Gold", "color": "#dbcdb3" }, { "name": "Silver Titanium", "color": "#aebac2" }, { "name": "Aura White", "color": "#c2c2c2" }, { "name": "Aura Glow", "color": "#777877" }, { "name": "Aura Pink", "color": "#dabbc1" }, { "name": "Aura Red", "color": "#a82b40" }, { "name": "Aura Black", "color": "#54544f" }, { "name": "Aura Blue", "color": "#9cb9df" }, { "name": "Prism Black", "color": "#2b2822" }, { "name": "Flamingo Pink", "color": "#c45e4e" }, { "name": "Prism White", "color": "#e3e3e3" }, { "name": "Prism Blue", "color": "#6a6f7a" }, { "name": "Prism Green", "color": "#606661" }, { "name": "Ceramic White", "color": "#e2ddd6" }, { "name": "Cloud Blue", "color": "#9db2c2" }, { "name": "Cosmic Gray", "color": "#6d6c73" }, { "name": "Cloud Pink", "color": "#cda8af" }, { "name": "Cosmic Black", "color": "#242424" }, { "name": "Clearly White", "color": "#f1f1f1" }, { "name": "Not Pink", "color": "#ee9066" }, { "name": "Just Black", "color": "#3f3f3f" } ];
  const colors: { name: string, color: string }[] = [];

  for (const device of devices) {
    // if (device.platform !== 'ios' || device.type === 'watch') {
    //   continue;
    // }

    for (const imgId in device.images) {
      colors.push({
        name: device.images[ imgId ].name,
        color: device.images[ imgId ].color,
      })
    }
  }


  const list = uniqBy(colors.filter(color => color.name !== 'White' && color.color !== '#FFFFFF'), 'name');
  return list;
}

const loadListLive = (): AppThunk => async (dispatch, getState) => {
  const { db } = await getFirebase();


  if (cancelLive) {
    return;
  }

  if (listLoaded) {
    dispatch(service.actions.setDevices({}));
  }

  try {
    dispatch(service.actions.setListLoading(true));

    cancelLive = firestoreLiveQuery<Device>({
      query: db.collection('devices').orderBy('name', 'asc'),
      skipInitialOnAdd: true,
      onAdd: (device) => {
        dispatch(service.actions.setDevice(device));
      },
      onUpdate: (device) => {
        dispatch(service.actions.setDevice(device));
      },
      onDelete: (device) => {
        dispatch(service.actions.unsetDevice(device.id));
      },
      onInitial: (devices) => {
        dispatch(service.actions.setListLoading(false));
        dispatch(service.actions.setDevices(keyBy(devices, 'id')));
      }

    })

    return true;

  }
  catch (e) {
    console.error(e);
    return false;
  }
}

const reset = (): AppThunk => async (dispatch, getState) => {
  cancelLive && cancelLive();
  cancelLive = null;
}


let listLoaded = false;

const loadList = (): AppThunk => async (dispatch, getState) => {

  if (listLoaded) {
    return;
  }

  if (cancelLive) {
    cancelLive();
    cancelLive = null;
    dispatch(service.actions.setDevices({}));
  }

  const { db } = await getFirebase();

  try {
    const query = db.collection('devices')
      .where('status', '==', 'active')
      .orderBy('name', 'asc');

    const devices = await firestoreQuery<Device>(query);

    const devicesMap = keyBy(devices, 'id');
    dispatch(service.actions.setDevices(devicesMap));
    listLoaded = true;

    return true;
  }
  catch (e) {
    console.error(e);
    return false;
  }
  finally {
    dispatch(service.actions.setListLoading(false));
  }
}

export const loadDevice = async (id: string) => {
  const { db } = await getFirebase();

  try {
    const data = await db.collection('devices').doc(id).get();
    const device = processItem<Device>(data);
    return device;

  }
  catch (e) {
    console.error(e);
    return null;
  }
}


const updateSort = (devices: Device[]): AppThunk => async (dispatch, getState) => {
  const { db } = await getFirebase();
  try {
    const promises: Promise<any>[] = [];
    const newList = devices.map((device, index) => {
      promises.push(
        db.collection('devices').doc(device.id).update({ sort: index })
      );
      return {
        ...device,
        sort: index
      }
    })

    dispatch(service.actions.setDevices(
      keyBy(newList, 'id')
    ))
    await Promise.all(promises);
  }
  catch (e) {
    console.error(e);
  }
  finally {
  }

}

export const actions = {
  loadListLive,
  loadList,
  reset,
  updateSort,
  setDevice: service.actions.setDevice,
}

export default service.reducer;