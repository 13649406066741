import React from "react";
import clsx from 'clsx';
import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";
import IconPhone from '@material-ui/icons/Phone';

import { AuthMethod } from "../../types";
import Button from '../../components/ProgressButton';

import GoogleIcon from '../../icons/google.svg';
import FacebookIcon from '../../icons/facebook.svg';
import TwitterIcon from '../../icons/twitter.svg';
import GithubIcon from '../../icons/github.svg';


const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      button: {
        // width: 300,
        width: '100%',

      },
      phone: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        '&:hover': {
          backgroundColor: theme.palette.primary.dark,
        }
      },
      facebook: {
        backgroundColor: '#3b5999',
        color: '#fff',
        '&:hover': {
          backgroundColor: '#304b84',
        }
      },
      google: {
        backgroundColor: '#ca3f23',
        color: '#fff',
        '&:hover': {
          backgroundColor: '#ad351c',
        }
      },
      twitter: {
        backgroundColor: '#1da1f2',
        color: '#fff',
        '&:hover': {
          backgroundColor: '#198fd8',
        }
      },
      github: {
        backgroundColor: '#444',
        color: '#fff',
        '&:hover': {
          backgroundColor: '#555',
        }
      },
      socialIcon: {
        width: 18,
        height: 18,
        fill: '#fff',
      },
    }
  ),
);

interface Props {
  loading?: boolean;
  type: AuthMethod;
  onClick?: () => void;
}

const AuthButton: React.FC<Props> = ({ loading, type, onClick }) => {
  const classes = useStyles();


  const buildTwitter = () => (
    <Button
      size="large"
      variant="contained"
      className={clsx(classes.button, classes.twitter)}
      startIcon={<TwitterIcon className={classes.socialIcon} />}
      onClick={onClick}
      loading={Boolean(loading)}
      disableElevation={true}
    >
      Continue with Twitter
    </Button>
  )

  const buildFacebook = () => (
    <Button
      size="large"
      variant="contained"
      className={clsx(classes.button, classes.facebook)}
      loading={Boolean(loading)}
      onClick={onClick}
      startIcon={<FacebookIcon className={classes.socialIcon} />}
      disableElevation={true}
    >
      Continue with Facebook
    </Button>
  )

  const buildGoogle = () => (
    <Button
      size="large"
      variant="contained"
      className={clsx(classes.button, classes.google)}
      loading={Boolean(loading)}
      onClick={onClick}
      startIcon={<GoogleIcon className={classes.socialIcon} />}
      disableElevation={true}
    >
      Continue with Google
    </Button>
  )

  const buildGithub = () => (
    <Button
      size="large"
      variant="contained"
      className={clsx(classes.button, classes.github)}
      loading={Boolean(loading)}
      onClick={onClick}
      startIcon={<GithubIcon className={classes.socialIcon} />}
      disableElevation={true}
    >
      Continue with GitHub
    </Button>
  )

  if (type === 'twitter.com') {
    return buildTwitter();
  }
  else if (type === 'facebook.com') {
    return buildFacebook();
  }
  else if (type === 'google.com') {
    return buildGoogle();
  }
  else if (type === 'github.com') {
    return buildGithub();
  }

  return null;
};

export default AuthButton;