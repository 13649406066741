import { getFirebase } from '../firebase';
import { UserData } from 'repix-common';
import { AuthMethod } from '../types';

export const setUerData = async (user: UserData) => {
  const { analytics } = await getFirebase();

  analytics.setUserId(user.id);
}


type EventType = 'page_view' | 'login' | 'sign_up' | 'project_create' | 'project_delete' | 'font_upload' | 'font_delete'
  | 'image_upload' | 'image_delete' | 'export_create' | 'export_download' 
  | 'project_design_create' | 'project_design_delete' | 'project_designs_import';

export const trackEvent = async (eventName: EventType, data?: { [ key: string ]: any }) => {
  try {
    const { analytics } = await getFirebase();
    analytics.logEvent(eventName as string, data);
  }
  catch (e) {
    console.error('trackEvent', e);
  }
}

export const trackPageView = () => {
  trackEvent('page_view');
}

export const trackSignIn = (authMethod: AuthMethod) => {
  trackEvent('login', { method: authMethod });
}

export const trackSignUp = (authMethod: AuthMethod) => {
  trackEvent('sign_up', { method: authMethod });
}