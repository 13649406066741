import { combineReducers, configureStore, Action } from '@reduxjs/toolkit';
import { ThunkAction } from 'redux-thunk';

import user, { actions as userActions } from './services/user';
import devices from './services/devices';
import designer from './services/designer';
import editDevice, { actions as eActions } from './services/editDevice';
import userImages from './services/userImages';
import stockPhotos from './services/stockPhotos';
import designElements from './services/designElements';
import projects from './services/projects';
import fonts from './services/fonts';
import exports from './services/exports';
import templates from './services/templates';
import support from './services/support';
import notifications from './services/notifications';
import reAuth from './services/reAuth';
import stats from './services/stats';
import adminProjects from './services/adminProjects';

const rootReducer = combineReducers({
  user,
  devices,
  editDevice,
  designer,
  userImages,
  stockPhotos,
  designElements,
  projects,
  fonts,
  exports,
  templates,
  support,
  notifications,
  reAuth,
  stats,
  adminProjects,
});

export type RootState = ReturnType<typeof rootReducer>;

export type AppThunk<T = void> = ThunkAction<T, RootState, null, Action<string>>;

const store = configureStore({
  reducer: rootReducer
});

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./store', () => {
    store.replaceReducer(rootReducer);
  });
}

if (typeof window !== 'undefined') {
  store.dispatch(userActions.init() as any);
  // store.dispatch(testActions.editDevice(true) as any);
  // setTimeout(() => {
  //   store.dispatch(eActions.setData(null));
  // }, 5000);
}

export type AppDispatch = typeof store.dispatch;
export default store; 